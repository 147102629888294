<style>
  #dv-left-program { position: absolute; left: -1100px; top: 20px; width: 1000px; }

  #program_caption { position: absolute; left: 50px; top: 35px; font-size: 26px; font-weight: 550; }

  #p_Left_2 { position: absolute; left: 0; top: 0; }
  #p_Left_2 .text{ font-family: 'Work Sans', sans-serif, "microsoft yahei"; font-size: 14px; }/* 'Teko' */
  #p_Left_2 .text span{ color: #00ffff; }/* 'Teko' */

  #p_Left_2 .bg01{ position: absolute; left: 45px; top: 100px; }
  #p_Left_2 .videos{ position: absolute; left: 55px; top: 109px; width: 400px; }
  #p_Left_2 .videos .videoBox{ position: absolute; left: 0; top: 0; text-align: center; width: 400px; line-height: 176px; color: #0055AA; }
  #p_Left_2 .videos .video-0-bg{ position: absolute; left: 156px; top: 88px; height: 0; }
  #p_Left_2 .videos .video-0{ position: absolute; left: 0; top: 0; width: 400px; height: 225px; }
  #p_Left_2 .videos .smallpic{ position: absolute; top: 205px; width: 90px; height: 50px; }
  #p_Left_2 .videos .video-1{ display: none; }
   #p_Left_2 .videos img{width: 400px;height: 225px;;}
  #p_Left_2 .address{ position: absolute; left: 55px; top: 350px; width: 400px; }
  #p_Left_2 .department{ position: absolute; left: 55px; top: 375px; width: 400px; padding-bottom: 10px; border-bottom: 1px solid #2727d4; }
  #p_Left_2 .explains{ position: absolute; left: 55px; top: 405px; width: 400px; line-height: 170%; height: 60px; font-size: 12px; overflow: hidden; text-align: justify; border-top: 1px solid #4040c7; padding-top: 10px }

  #p_Left_2 .bg02-1 { position: absolute; left: 45px; top: 495px; }
  #p_Left_2 .bg02-2 { position: absolute; left: 260px; top: 495px; }
  #p_Left_2 .bg02-3 { position: absolute; left: 45px; top: 565px; }
  #p_Left_2 .bg02-4 { position: absolute; left: 260px; top: 565px; }
  #p_Left_2 .lable_tnlTotalLength{ position: absolute; left:  55px; top: 503px; width: 200px; font-weight: bold; font-size: 14px; }
  #p_Left_2 .lable_tnlBetonLength{ position: absolute; left: 270px; top: 503px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_tnlCheckLength{ position: absolute; left:  55px; top: 573px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_tnlCheckRate  { position: absolute; left: 270px; top: 573px; width: 200px; font-weight: bold; }
  #p_Left_2 .value_tnlTotalLength{ position: absolute; left:  55px; top: 520px; width: 200px; font-size: 28px; }
  #p_Left_2 .value_tnlBetonLength{ position: absolute; left: 270px; top: 520px; width: 200px; font-size: 28px; }
  #p_Left_2 .value_tnlCheckLength{ position: absolute; left:  55px; top: 590px; width: 200px; font-size: 28px; }
  #p_Left_2 .value_tnlCheckRate  { position: absolute; left: 270px; top: 590px; width: 200px; font-size: 28px; }

  #p_Left_2 .bg04-1{ position: absolute; left:  45px; top: 635px; }
  #p_Left_2 .bg04-2{ position: absolute; left: 188px; top: 635px; }
  #p_Left_2 .bg04-3{ position: absolute; left: 330px; top: 635px; }
  #p_Left_2 .bg04-4{ position: absolute; left:  45px; top: 740px; }
  #p_Left_2 .bg04-5{ position: absolute; left: 188px; top: 740px; cursor: pointer; }
  #p_Left_2 .bg04-6{ position: absolute; left: 330px; top: 740px; cursor: pointer; }
  #p_Left_2 .lable_thickCount { position: absolute; left:  45px; top: 640px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_2 .lable_otherCount { position: absolute; left: 188px; top: 640px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_2 .lable_rebarCount { position: absolute; left: 330px; top: 640px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_2 .lable_betonCount { position: absolute; left:  45px; top: 745px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_2 .lable_betonCount2{ position: absolute; left: 188px; top: 745px; width: 135px; font-weight: bold; pointer-events: none; font-size: 12px; text-align: center; }
  #p_Left_2 .lable_betonCount3{ position: absolute; left: 330px; top: 745px; width: 135px; font-weight: bold; pointer-events: none; font-size: 12px; text-align: center; }
  #p_Left_2 .value_thickCount { position: absolute; left:  55px; top: 675px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_2 .value_otherCount { position: absolute; left: 198px; top: 675px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_2 .value_rebarCount { position: absolute; left: 340px; top: 675px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_2 .value_betonCount { position: absolute; left:  55px; top: 780px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_2 .value_betonCount2{ position: absolute; left: 198px; top: 780px; width: 190px; font-size: 32px; font-weight: bold; pointer-events: none; }
  #p_Left_2 .value_betonCount3{ position: absolute; left: 340px; top: 780px; width: 190px; font-size: 32px; font-weight: bold; pointer-events: none; }
  #p_Left_2 .bg05-1{ position: absolute; left: 114px; top: 663px; width: 64px; }
  #p_Left_2 .bg05-2{ position: absolute; left: 257px; top: 663px; width: 64px; }
  #p_Left_2 .bg05-3{ position: absolute; left: 400px; top: 663px; width: 64px; }
  #p_Left_2 .bg05-4{ position: absolute; left: 114px; top: 768px; width: 64px; }
  #p_Left_2 .bg05-5{ position: absolute; left: 257px; top: 768px; width: 64px; pointer-events: none; }
  #p_Left_2 .bg05-6{ position: absolute; left: 400px; top: 768px; width: 64px; pointer-events: none; }
  #p_Left_2 .rate_thickCount { position: absolute; left:  85px; top: 683px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_otherCount { position: absolute; left: 228px; top: 683px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_rebarCount { position: absolute; left: 371px; top: 683px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_betonCount { position: absolute; left:  85px; top: 788px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_betonCount2{ position: absolute; left: 228px; top: 788px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; pointer-events: none; }
  #p_Left_2 .rate_betonCount3{ position: absolute; left: 371px; top: 788px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; pointer-events: none; }

  #p_Left_2 .rate_thickCount span { color: #FE0001; }
  #p_Left_2 .rate_otherCount span { color: #0055FE; }
  #p_Left_2 .rate_rebarCount span { color: #FFA518; }
  #p_Left_2 .rate_betonCount span { color: #9094A0; }
  #p_Left_2 .rate_betonCount3 span{ color: #8428A1; }

  #p_Left_2 .bg03{ position: absolute; left:  45px; top: 845px; }
  #p_Left_2 .bg06{ position: absolute; left: 260px; top: 845px; }
  #p_Left_2 .bg07{ position: absolute; left:  45px; top: 940px; }
  #p_Left_2 .bg08{ position: absolute; left: 260px; top: 940px; }

  #p_Left_2 .lable_defectRepair { position: absolute; left:  55px; top: 848px; width: 204px; font-weight: bold; }
  #p_Left_2 .lable_defectInfo   { position: absolute; left: 270px; top: 848px; width: 204px; font-weight: bold; }
  #p_Left_2 .lable_keyDefectInfo{ position: absolute; left:  55px; top: 943px; width: 204px; font-weight: bold; }
  #p_Left_2 .lable_tunnelInfo   { position: absolute; left: 270px; top: 943px; width: 204px; font-weight: bold; }

  #p_Left_2 .value_defectRepair  { position: absolute; left:  55px; top: 883px; width: 184px; font-weight: bold; }
  #p_Left_2 .value_defectInfo    { position: absolute; left: 270px; top: 883px; width: 184px; font-weight: bold; }
  #p_Left_2 .value_keyDefectInfo { position: absolute; left:  55px; top: 978px; width: 184px; font-weight: bold; }
  #p_Left_2 .value_tunnelInfo    { position: absolute; left: 270px; top: 978px; width: 184px; font-weight: bold; }

  #p_Left_2 .value_defectRepair  span { font-size: 32px; }
  #p_Left_2 .value_defectInfo    span { font-size: 32px; }
  #p_Left_2 .value_keyDefectInfo span { font-size: 32px; }
  #p_Left_2 .value_tunnelInfo    span { font-size: 32px; }
  #p_Left_2 .value_defectRepair  div:nth-child(1),
  #p_Left_2 .value_defectInfo    div:nth-child(1),
  #p_Left_2 .value_tunnelInfo    div:nth-child(1),
  #p_Left_2 .value_keyDefectInfo div:nth-child(1) { position: absolute; left: 0; top: 0; width: 120px; font-weight: bold; text-align: left; }

  #p_Left_2 .value_defectRepair  i,
  #p_Left_2 .value_defectInfo    i,
  #p_Left_2 .value_tunnelInfo    i,
  #p_Left_2 .value_keyDefectInfo i { position: absolute; left: 80px; top: 0; font-size: 32px; font-style: normal; color: #5d91a7; }

  #p_Left_2 .value_defectRepair  div:nth-child(3),
  #p_Left_2 .value_defectInfo    div:nth-child(3),
  #p_Left_2 .value_tunnelInfo    div:nth-child(3),
  #p_Left_2 .value_keyDefectInfo div:nth-child(3) { position: absolute; right: 0; top: 0; width: 120px; font-weight: bold; text-align: right; }

  #p_Left_2 .value_defectRepair  div span,
  #p_Left_2 .value_defectInfo    div span,
  #p_Left_2 .value_tunnelInfo    div span,
  #p_Left_2 .value_keyDefectInfo div span{ font-family: 'Fjalla One', sans-serif; font-size: 32px; margin-right: 5px; }

</style>

<template>
  <div style="z-index:4" id="dv-left-program">
    <div>
      <div id="program_caption" v-text="ProjectSimpleInfo.Name"></div>
    </div>

    <div id="p_Left_2" class="page">
      <dv-img class="bg01" src="imgs/program/视屏边框.png"></dv-img>
      <!-- <div class="videos" style="z-index:5">
        <div class="videoBox">
          <dv-img id="video-10-bg" class="video-0-bg" src="imgs/p10/76126803-86ed-4e94-9f3a-589f5cb608e8.png" ></dv-img>
          <video id="video-10" class="video-0" autoplay="autoplay" loop="loop" muted="muted" :src="currentProgramVideo" @click="scaleVideo" style="z-index:5"></video>
          <img src="" alt="">
          视频已放大，点击视频恢复
        </div>
        <dv-img v-for="(video, index) in VideoList.slice(0, 1)" :key="index" :class="'smallpic video-' + (index + 1) + ' videoImg'" :src="ImgBaseUrl + video.CoverLink" :data-src="video.OssUrl" @click="toggleProgramVideo"></dv-img>
      </div> -->
      <div class="videos">
        <img :src="ImgBaseUrl+ProjectSimpleInfo.ShowPic" alt="">
      </div>
      <div class="text address">项目地点：<span v-text="ProjectSimpleInfo.Address"></span></div>
      <div class="text department">项目部门：<span v-text="ProjectSimpleInfo.SiteName"></span></div>
      <div class="text explains"><vue-scroll><div v-text="ProjectSimpleInfo.Describe"></div></vue-scroll></div>
      
      <dv-img class="bg02-1" src="imgs/program/t1-bg.png"></dv-img>
      <dv-img class="bg02-2" src="imgs/program/t1-bg.png"></dv-img>
      <dv-img class="bg02-3" src="imgs/program/t1-bg.png"></dv-img>
      <dv-img class="bg02-4" src="imgs/program/t1-bg.png"></dv-img>
      <div class="text lable_tnlTotalLength">规划隧道总长（M）</div>
      <div class="text lable_tnlBetonLength">衬砌总长（M）</div>
      <div class="text lable_tnlCheckLength">已检测总长（M）</div>
      <div class="text lable_tnlCheckRate">已检覆盖率（%）</div>
      <div class="text value_tnlTotalLength"><span v-text="DesignLength"></span></div>
      <div class="text value_tnlBetonLength"><span v-text="CrackLength"></span></div>
      <div class="text value_tnlCheckLength"><span v-text="TestLength"></span></div>
      <div class="text value_tnlCheckRate"><span v-text="TestRate"></span></div>
      
      <dv-img class="bg04-1" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-2" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-3" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-4" src="imgs/program/数据图表1-bg.png"></dv-img>
      <div class="text lable_thickCount">厚度不足（处）/占比</div>
      <div class="text lable_otherCount">空洞、不密实(处)/占比</div>
      <div class="text lable_rebarCount">钢筋缺失（处）/占比</div>
      <div class="text lable_betonCount">防裂钢筋网（处）/占比</div>
      <!-- <div class="text lable_betonCount2">7天超时确认(处)/占比</div>
      <div class="text lable_betonCount3">30天超时确认(处)/占比</div> -->
      <div class="text value_thickCount"><span v-text="FaultCount1"></span></div>
      <div class="text value_otherCount"><span v-text="FaultCount4"></span></div>
      <div class="text value_rebarCount"><span v-text="FaultCount2"></span></div>
      <div class="text value_betonCount"><span v-text="FaultCount3"></span></div>
      <!-- <div class="text value_betonCount2"><span v-text="FaultCount5"></span></div>
      <div class="text value_betonCount3"><span v-text="FaultCount6"></span></div> -->
      <dv-img class="bg05-1" src="imgs/p101/03-yuan-1.png"></dv-img>
      <dv-img class="bg05-2" src="imgs/p101/03-yuan-2.png"></dv-img>
      <dv-img class="bg05-3" src="imgs/p101/03-yuan-3.png"></dv-img>
      <dv-img class="bg05-4" src="imgs/p101/03-yuan-4.png"></dv-img>
      <!-- <dv-img class="bg05-5" src="imgs/p101/03-yuan-5.png"></dv-img>
      <dv-img class="bg05-6" src="imgs/p101/03-yuan-6.png"></dv-img> -->
      <div class="text rate_thickCount"><span v-text="(FaultCount1 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_otherCount"><span v-text="(FaultCount4 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_rebarCount"><span v-text="(FaultCount2 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount"><span v-text="(FaultCount3 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <!-- <div class="text rate_betonCount2"><span v-text="(FaultCount5 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount3"><span v-text="(FaultCount6 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div> -->
      
      <dv-img class="bg03" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <dv-img class="bg06" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <dv-img class="bg07" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <dv-img class="bg08" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <div class="text lable_defectRepair">缺陷修复率 / 重大缺陷修复率</div>
      <div class="text lable_defectInfo">缺陷总数 / 缺陷率</div>
      <div class="text lable_keyDefectInfo">重大缺陷 / 重大缺陷率</div>
      <div class="text lable_tunnelInfo">缺陷修复 / 重大缺陷修复</div>
      <div class="text value_defectRepair">
        <div><span v-text="TotalFaultFixRate"></span>%</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadFixRate"></span>%</div>
      </div>
      <div class="text value_defectInfo">
        <div><span v-text="TotalFaultCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultRate"></span>%</div>
      </div>
      <div class="text value_keyDefectInfo">
        <div><span v-text="TotalFaultBadCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadRate"></span>%</div>
      </div>
      <div class="text value_tunnelInfo">
        <div><span v-text="TotalFaultFixCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadFixCount"></span>处</div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from 'jquery';
import dataV from '../../bin/dataV';
import dvImg from "./dv-img.vue";

export default {
  data() {
    return {
      Id: 'LeftProgramComponent',

      AreaName: dataV.Config.AreaName,
      AreaDescription: dataV.Config.AreaDescription,
      ImgBaseUrl: dataV.Config.baseUrl.img,
      currentProgramVideo: '',

      CrackLength: 0,
      CrackRate: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      ShowPic :'',
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      ProjectSimpleInfo: {Name: "", SiteName: "", Address: "", Describe: "",ShowPic:""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,
      TunnelCompareFault: [],
      TunnelCompareFix: [],
      TunnelCount: 0,
     
      TotalFaultFixCount: 0,
      TotalFaultBadFixCount: 0,
      
      WindowStatus: {
        isProgramVideoOpen: false,
      },

    };
  },
  props: [],
  computed: {
    _TotalFaultCount(){
      let t = this.FaultCount1 + this.FaultCount2 + this.FaultCount3 + this.FaultCount4
      if(t == 0) t = 1
      return t
    }
  },
  methods: {

      GetSevens(){
				this.$api.request({ Vue: this, apiName: 'checkOver', query: { tempId: dataV.Page.CurrentId, datatype: 1 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('sevens', json)
					} else {
						alert(response.message)
					}
				})
			},

      GetMonths(){
				this.$api.request({ Vue: this, apiName: 'fixOver', query: { tempId: dataV.Page.CurrentId, datatype: 1 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('months', json)
					} else {
						alert(response.message)
					}
				})
			},

        
    toggleProgramVideo(){
      var $v = $(event.target);
      this.currentProgramVideo = $v.data('src');
    },

    // 手动方式，设置当前模块的语音声控指令，已达到手动控制的效果
    scaleVideo(){
      if($(event.target).width() < 500){
        this.enlargeVideo();
      }else{
        this.narrowVideo();
      }
    },

    // 放大视频
    enlargeVideo(){
      dataV.ModelWindows.close('项目视频');
      this.WindowStatus.isProgramVideoOpen = true;

      var el = document.getElementById('video-10');
      $('#video-10-bg').css({'z-index': 7}).animate({
        left: '346px',
        top: '0px',
        width: '1148px',
        height: '774px',
        'z-index': 7
      }, 500);
      $(el).css({'z-index': 7}).animate({
        left: '352px',
        top: '66px',
        width: '1136px',
        height: '639px',
        'z-index': 7
      }, 500, function(){
        el.muted = false;
        el.loop = false;
        el.currentTime = 0;
      });
    },

    // 缩小视频
    narrowVideo(){        
      // 缩小项目部视频
      if(this.WindowStatus.isProgramVideoOpen){
        this.WindowStatus.isProgramVideoOpen = false;
        var el2 = document.getElementById('video-10');
        el2.muted = true;
        el2.loop = true;
        $('#video-10-bg').animate({
          left: '156px',
          top: '88px',
          width: 0,
          height: 0,
        }, 400);
        $(el2).animate({
          left: 0,
          top: 0,
          width: '400px',
          height: '225px'
        }, 500);
      }
    },
    
    // 切换视频
    toggleVideo () {
      var $v = $(event.target);
      this.currentVideo = $v.data('src');
    },

  },
  created() {
			window[this.Id] =  this;
  },
  mounted() { 
			// 注册弹出窗口
			dataV.ModelWindows.regist('项目视频', this.WindowStatus, 'isProgramVideoOpen', true, this.narrowVideo);
  },
  watch: {
    ProjectSimpleInfo() {
      if(!window.ApiData[dataV.Page.CurrentId]) return;
      this.ProjectSimpleInfo = window.ApiData[dataV.Page.CurrentId].ProjectSimpleInfo
      // this.currentProgramVideo = window.ApiData[dataV.Page.CurrentId].VideoList.length > 0 ? window.ApiData[dataV.Page.CurrentId].VideoList[0].OssUrl : ''
    },
    // ShowPic(){
    //   if(!window.ApiData[dataV.Page.CurrentId]) return;
    // }
  },
  components: {
    "dv-img": dvImg,
  },
};
</script>
