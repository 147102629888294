<style>
#dv-header {
  position: absolute;
  left: 0;
  top: -150px;
  text-align: center;
  overflow: hidden;
  width: 1920px;
  height: 200px;
  background: url('../../../public/imgs/标题改.png') center top no-repeat;
  pointer-events: none;
}
/* #dv-header .bg {
  width: 3840px;
  height: 109px;
} */
</style>

<template>
  <div id="dv-header">
  </div>
</template>

<script>
// import dvImg from "./dv-img.vue";

export default {
  data: function(){
    return {
          Id: 'TopHeaderComponent',
        }
  },
  props: ["title"], 
  created() {
    window[this.Id] =  this;
  },
  methods: {},
  components: {
    // 'dv-img': dvImg,
  },
};
</script>
