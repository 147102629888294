<template>
  <div
    v-if="type === 'svg'"
    :id="id"
    v-on:click="$emit('click')"
    v-on:mousedown="$emit('mousedown')"
  ></div>
  <img
    v-else
    :id="id"
    :src="src"
    :style="scale"
    v-on:click="$emit('click')"
    v-on:mousedown="$emit('mousedown')"
    v-on:mouseover="$emit('mouseover')"
    v-on:mouseleave="$emit('mouseleave')"
    :usemap="usemap"
  />
</template>

<script>
import lottie from 'lottie-web';

export default {
  data() {
    return {
      id: "img_" + Math.random(),
    };
  },
  props: ["target", "src", "type", "loop", "autoplay", "usemap", "scale"],
  mounted() {

    if (this.src && this.type === "svg") {
      this[this.id] = lottie.loadAnimation({
        container: document.getElementById(this.id), // Required
        path: this.src, // Required
        renderer: "svg", // Required    /canvas/html
        loop: this.loop === true || this.loop === "true" ? true : false, // Optional
        autoplay: this.autoplay === false || this.autoplay === "false" ? false : true, // Optional
        name: this.id, // Name for future reference. Optional.
      });
    }
  },
};
</script>

<style>
</style>
