<style>
  #dv-left-tunnel { position: absolute; left: -1100px; top: 0; width: 1000px; }

  #dv-left-tunnel #tunnel_caption  { position: absolute; left: 50px; top:  35px; font-size: 26px; font-weight: 550; }
  #dv-left-tunnel #tunnel_caption2 { position: absolute; left: 50px; top: 440px; font-size: 26px; font-weight: 550; }

  #p_Left_3 { position: absolute; left: 0; top: 0; }
  #p_Left_3 i { font-style: normal; }
  #p_Left_3 .text{ font-family: 'Work Sans', sans-serif, "microsoft yahei"; font-size: 15px; }/* 'Teko' */
  #p_Left_3 .text span{ color: #00ffff; }/* 'Teko' */
  #p_Left_3 .bg01{ position: absolute; left: 45px; top: 100px; }
  #p_Left_3 #tunnelKMs { position: absolute; left: 75px; top: 131px; font-size: 20px; font-weight: bold; width: 250px; overflow: hidden; }
  #p_Left_3 .programType{ position: absolute; left: 355px; top: 135px; width: 130px; font-size: 12px; }
  #p_Left_3 .department{ position: absolute; left: 60px; top: 380px; width: 400px; }
  #p_Left_3 .address{ position: absolute; left: 60px; top: 355px; width: 400px; }

  #p_Left_3 .bg02-1 { position: absolute; left: 45px; top: 495px; }
  #p_Left_3 .bg02-2 { position: absolute; left: 260px; top: 495px; }
  #p_Left_3 .bg02-3 { position: absolute; left: 45px; top: 565px; }
  #p_Left_3 .bg02-4 { position: absolute; left: 260px; top: 565px; }
  #p_Left_3 .lable_tnlTotalLength{ position: absolute; left:  55px; top: 503px; width: 200px; font-weight: bold; font-size: 14px; }
  #p_Left_3 .lable_tnlBetonLength{ position: absolute; left: 270px; top: 503px; width: 200px; font-weight: bold; }
  #p_Left_3 .lable_tnlCheckLength{ position: absolute; left:  55px; top: 573px; width: 200px; font-weight: bold; }
  #p_Left_3 .lable_tnlCheckRate  { position: absolute; left: 270px; top: 573px; width: 200px; font-weight: bold; }
  #p_Left_3 .value_tnlTotalLength{ position: absolute; left:  55px; top: 520px; width: 200px; font-size: 28px; }
  #p_Left_3 .value_tnlBetonLength{ position: absolute; left: 270px; top: 520px; width: 200px; font-size: 28px; }
  #p_Left_3 .value_tnlCheckLength{ position: absolute; left:  55px; top: 590px; width: 200px; font-size: 28px; }
  #p_Left_3 .value_tnlCheckRate  { position: absolute; left: 270px; top: 590px; width: 200px; font-size: 28px; }

  #p_Left_3 .bg04-1{ position: absolute; left:  45px; top: 635px; }
  #p_Left_3 .bg04-2{ position: absolute; left: 188px; top: 635px; }
  #p_Left_3 .bg04-3{ position: absolute; left: 330px; top: 635px; }
  #p_Left_3 .bg04-4{ position: absolute; left:  45px; top: 740px; }
  #p_Left_3 .bg04-5{ position: absolute; left: 188px; top: 740px; cursor: pointer; }
  #p_Left_3 .bg04-6{ position: absolute; left: 330px; top: 740px; cursor: pointer; }
  #p_Left_3 .lable_thickCount { position: absolute; left:  45px; top: 640px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_3 .lable_otherCount { position: absolute; left: 188px; top: 640px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_3 .lable_rebarCount { position: absolute; left: 330px; top: 640px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_3 .lable_betonCount { position: absolute; left:  45px; top: 745px; width: 135px; font-weight: bold; font-size: 12px; text-align: center; }
  #p_Left_3 .lable_betonCount2{ position: absolute; left: 188px; top: 745px; width: 135px; font-weight: bold; pointer-events: none; font-size: 12px; text-align: center; }
  #p_Left_3 .lable_betonCount3{ position: absolute; left: 330px; top: 745px; width: 135px; font-weight: bold; pointer-events: none; font-size: 12px; text-align: center; }
  #p_Left_3 .value_thickCount { position: absolute; left:  55px; top: 675px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_3 .value_otherCount { position: absolute; left: 198px; top: 675px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_3 .value_rebarCount { position: absolute; left: 340px; top: 675px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_3 .value_betonCount { position: absolute; left:  55px; top: 780px; width: 190px; font-size: 32px; font-weight: bold; }
  #p_Left_3 .value_betonCount2{ position: absolute; left: 198px; top: 780px; width: 190px; font-size: 32px; font-weight: bold; pointer-events: none; }
  #p_Left_3 .value_betonCount3{ position: absolute; left: 340px; top: 780px; width: 190px; font-size: 32px; font-weight: bold; pointer-events: none; }
  #p_Left_3 .bg05-1{ position: absolute; left: 114px; top: 663px; width: 64px; }
  #p_Left_3 .bg05-2{ position: absolute; left: 257px; top: 663px; width: 64px; }
  #p_Left_3 .bg05-3{ position: absolute; left: 400px; top: 663px; width: 64px; }
  #p_Left_3 .bg05-4{ position: absolute; left: 114px; top: 768px; width: 64px; }
  #p_Left_3 .bg05-5{ position: absolute; left: 257px; top: 768px; width: 64px; pointer-events: none; }
  #p_Left_3 .bg05-6{ position: absolute; left: 400px; top: 768px; width: 64px; pointer-events: none; }
  #p_Left_3 .rate_thickCount { position: absolute; left:  85px; top: 683px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_3 .rate_otherCount { position: absolute; left: 228px; top: 683px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_3 .rate_rebarCount { position: absolute; left: 371px; top: 683px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_3 .rate_betonCount { position: absolute; left:  85px; top: 788px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; }
  #p_Left_3 .rate_betonCount2{ position: absolute; left: 228px; top: 788px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; pointer-events: none; }
  #p_Left_3 .rate_betonCount3{ position: absolute; left: 371px; top: 788px; width: 124px; font-size: 18px; text-align: center; font-weight: bold; pointer-events: none; }

  #p_Left_3 .rate_thickCount span { color: #FE0001; }
  #p_Left_3 .rate_otherCount span { color: #0055FE; }
  #p_Left_3 .rate_rebarCount span { color: #FFA518; }
  #p_Left_3 .rate_betonCount span { color: #9094A0; }
  #p_Left_3 .rate_betonCount3 span{ color: #8428A1; }

  #p_Left_3 .bg03{ position: absolute; left:  45px; top: 845px; }
  #p_Left_3 .bg06{ position: absolute; left: 260px; top: 845px; }
  #p_Left_3 .bg07{ position: absolute; left:  45px; top: 940px; }
  #p_Left_3 .bg08{ position: absolute; left: 260px; top: 940px; }

  #p_Left_3 .lable_defectRepair { position: absolute; left:  55px; top: 848px; width: 204px; font-weight: bold; }
  #p_Left_3 .lable_defectInfo   { position: absolute; left: 270px; top: 848px; width: 204px; font-weight: bold; }
  #p_Left_3 .lable_keyDefectInfo{ position: absolute; left:  55px; top: 943px; width: 204px; font-weight: bold; }
  #p_Left_3 .lable_tunnelInfo   { position: absolute; left: 270px; top: 943px; width: 204px; font-weight: bold; }

  #p_Left_3 .value_defectRepair  { position: absolute; left:  55px; top: 883px; width: 184px; font-weight: bold; }
  #p_Left_3 .value_defectInfo    { position: absolute; left: 270px; top: 883px; width: 184px; font-weight: bold; }
  #p_Left_3 .value_keyDefectInfo { position: absolute; left:  55px; top: 978px; width: 184px; font-weight: bold; }
  #p_Left_3 .value_tunnelInfo    { position: absolute; left: 270px; top: 978px; width: 184px; font-weight: bold; }

  #p_Left_3 .value_defectRepair  span { font-size: 32px; }
  #p_Left_3 .value_defectInfo    span { font-size: 32px; }
  #p_Left_3 .value_keyDefectInfo span { font-size: 32px; }
  #p_Left_3 .value_tunnelInfo    span { font-size: 32px; }
  #p_Left_3 .value_defectRepair  div:nth-child(1),
  #p_Left_3 .value_defectInfo    div:nth-child(1),
  #p_Left_3 .value_tunnelInfo    div:nth-child(1),
  #p_Left_3 .value_keyDefectInfo div:nth-child(1) { position: absolute; left: 0; top: 0; width: 120px; font-weight: bold; text-align: left; }

  #p_Left_3 .value_defectRepair  i,
  #p_Left_3 .value_defectInfo    i,
  #p_Left_3 .value_tunnelInfo    i,
  #p_Left_3 .value_keyDefectInfo i { position: absolute; left: 80px; top: 0; font-size: 32px; font-style: normal; color: #5d91a7; }

  #p_Left_3 .value_defectRepair  div:nth-child(3),
  #p_Left_3 .value_defectInfo    div:nth-child(3),
  #p_Left_3 .value_tunnelInfo    div:nth-child(3),
  #p_Left_3 .value_keyDefectInfo div:nth-child(3) { position: absolute; right: 0; top: 0; width: 120px; font-weight: bold; text-align: right; }

  #p_Left_3 .value_defectRepair  div span,
  #p_Left_3 .value_defectInfo    div span,
  #p_Left_3 .value_tunnelInfo    div span,
  #p_Left_3 .value_keyDefectInfo div span{ font-family: 'Fjalla One', sans-serif; font-size: 32px; margin-right: 5px; }

  .currentStart { position: absolute; left: 75px; top: 300px; width: 390px; text-align: center; }
  .currentStart div { display: inline-block; }

</style>

<template>
  <div id="dv-left-tunnel">
    <div id="tunnel_caption" v-text="Name"></div>
    <div id="tunnel_caption2">隧道质量信息</div>

    <div id="p_Left_3" class="page">
      <dv-img class="bg01" src="imgs/tunnel/bg-smallmap.png"></dv-img>
      

      <div id="tunnelKMs" v-text="ModelData.TunnelCode + ModelData.TunnelStartKiloMetre + '+' + ModelData.TunnelStartMetre + ' - ' + ModelData.TunnelCode + ModelData.TunnelEndKiloMetre + '+' + ModelData.TunnelEndMetre"></div>
      <div class="text department">承建单位：<i v-text="ModelData.ProjectDepartment"></i></div>
      <div class="text address">建设地点：<i v-text="ModelData.TunnelAddress"></i></div>
      <div class="text programType">项目类型：<i v-text="ModelData.ProjectType"></i></div>
      
      <dv-smalltunnel left="60" top="195" width="410" height="85" divcount="2" divlength="10" :code="tempTunnelInfoPath.code" :startkm="tempTunnelInfoPath.startKM" :startm="tempTunnelInfoPath.startM" :length="tempTunnelInfoPath.planLength" :defects="tempTunnelInfoPath.defects"></dv-smalltunnel>
      
      <div class="currentStart">
        <div class="text lable_tnlStartEnd">当前位置：</div>
        <div class="text value_tnlStartEnd"><span v-text="currentKMs"></span></div>
      </div>
      
            
      <dv-img class="bg02-1" src="imgs/program/t1-bg.png"></dv-img>
      <dv-img class="bg02-2" src="imgs/program/t1-bg.png"></dv-img>
      <dv-img class="bg02-3" src="imgs/program/t1-bg.png"></dv-img>
      <dv-img class="bg02-4" src="imgs/program/t1-bg.png"></dv-img>
      <div class="text lable_tnlTotalLength">规划隧道总长（M）</div>
      <div class="text lable_tnlBetonLength">衬砌总长（M）</div>
      <div class="text lable_tnlCheckLength">已检测总长（M）</div>
      <div class="text lable_tnlCheckRate">已检覆盖率（%）</div>
      <div class="text value_tnlTotalLength"><span v-text="DesignLength"></span></div>
      <div class="text value_tnlBetonLength"><span v-text="CrackLength"></span></div>
      <div class="text value_tnlCheckLength"><span v-text="TestLength"></span></div>
      <div class="text value_tnlCheckRate"><span v-text="TestRate"></span></div>
      
      <dv-img class="bg04-1" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-2" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-3" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-4" src="imgs/program/数据图表1-bg.png"></dv-img>
      <dv-img class="bg04-5" src="imgs/program/数据图表1-bg.png" @click="GetSevens"></dv-img>
      <dv-img class="bg04-6" src="imgs/program/数据图表1-bg.png" @click="GetMonths"></dv-img>
      <div class="text lable_thickCount">厚度不足（处）/占比</div>
      <div class="text lable_otherCount">空洞、不密实(处)/占比</div>
      <div class="text lable_rebarCount">钢筋缺失（处）/占比</div>
      <div class="text lable_betonCount">防裂钢筋网（处）/占比</div>
      <!-- <div class="text lable_betonCount2">7天超时确认(处)/占比</div>
      <div class="text lable_betonCount3">30天超时确认(处)/占比</div> -->
      <div class="text value_thickCount"><span v-text="FaultCount1"></span></div>
      <div class="text value_otherCount"><span v-text="FaultCount4"></span></div>
      <div class="text value_rebarCount"><span v-text="FaultCount2"></span></div>
      <div class="text value_betonCount"><span v-text="FaultCount3"></span></div>
      <!-- <div class="text value_betonCount2"><span v-text="FaultCount5"></span></div>
      <div class="text value_betonCount3"><span v-text="FaultCount6"></span></div> -->
      <dv-img class="bg05-1" src="imgs/p101/03-yuan-1.png"></dv-img>
      <dv-img class="bg05-2" src="imgs/p101/03-yuan-2.png"></dv-img>
      <dv-img class="bg05-3" src="imgs/p101/03-yuan-3.png"></dv-img>
      <dv-img class="bg05-4" src="imgs/p101/03-yuan-4.png"></dv-img>
      <!-- <dv-img class="bg05-5" src="imgs/p101/03-yuan-5.png"></dv-img>
      <dv-img class="bg05-6" src="imgs/p101/03-yuan-6.png"></dv-img> -->
      <div class="text rate_thickCount"><span v-text="(FaultCount1 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_otherCount"><span v-text="(FaultCount4 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_rebarCount"><span v-text="(FaultCount2 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount"><span v-text="(FaultCount3 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <!-- <div class="text rate_betonCount2"><span v-text="(FaultCount5 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount3"><span v-text="(FaultCount6 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div> -->
      
      <dv-img class="bg03" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <dv-img class="bg06" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <dv-img class="bg07" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <dv-img class="bg08" src="imgs/program/衬砌总长-bg.png"></dv-img>
      <div class="text lable_defectRepair">缺陷修复率 / 重大缺陷修复率</div>
      <div class="text lable_defectInfo">缺陷总数 / 缺陷率</div>
      <div class="text lable_keyDefectInfo">重大缺陷 / 重大缺陷率</div>
      <div class="text lable_tunnelInfo">缺陷修复 / 重大缺陷修复</div>
      <div class="text value_defectRepair">
        <div><span v-text="TotalFaultFixRate"></span>%</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadFixRate"></span>%</div>
      </div>
      <div class="text value_defectInfo">
        <div><span v-text="TotalFaultCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultRate"></span>%</div>
      </div>
      <div class="text value_keyDefectInfo">
        <div><span v-text="TotalFaultBadCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadRate"></span>%</div>
      </div>
      <div class="text value_tunnelInfo">
        <div><span v-text="TotalFaultFixCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadFixCount"></span>处</div>
      </div>

    </div>

  </div>
</template>

// <script>
// import * as $ from 'jquery';
import dataV from '../../bin/dataV'
import dvImg from "./dv-img.vue"
// import dvSmallTunnel from './dv-smalltunnel'
const dvSmallTunnel = () => import('./dv-smalltunnel.vue')

export default {
  data() {
    return {
      Id: 'LeftTunnelComponent',

      AreaName: dataV.Config.AreaName,
      ImgBaseUrl: dataV.Config.baseUrl.img,

      currentPageLevel: dataV.Config.initPage.level,
      currentArea: dataV.Config.AreaName,
      currentProgram: dataV.Config.initPage.program,
      currentTunnel: dataV.Config.initPage.tunnel,
      currentTunnelID: dataV.Config.initPage.tunnelId,
      currentDefect: dataV.Config.initPage.defect,
      currentKMs: '',

      BuildLength: 0,
      CrackLength: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultDetailList1: [],
      FaultDetailList2: [],
      FaultDetailList3: [],
      FaultDetailList4: [],
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      Name: "",
      PileNumCode: "",
      ProjectSimpleInfo: {BelongCategory: "", SiteName: "", Address: ""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,
      TotalFaultFixCount: '-',
      TotalFaultBadFixCount: '-',
      ModelData: {
        GoodKiloMetreInfo: null,
        OverKiloMetreInfo: {startKiloMetre: 99, startMetre: 482, endKiloMetre: 99, endMetre: 818},
        ProjectDepartment: "湖杭铁路项目经理部二分部",
        ProjectName: "湖杭铁路二分部",
        ProjectType: "铁路",
        SuiDaoDividerInfo: [],
        TunnelAddress: "浙江省富阳市",
        TunnelCode: "DK",
        TunnelEndKiloMetre: 0,
        TunnelEndMetre: 0,
        TunnelName: "羊岩坞二号隧道出口",
        TunnelStartKiloMetre: 0,
        TunnelStartMetre: 0,
        ViewKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
        WorkKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
        bugs: [],
        checkLines: [],
      },

      tempTunnelInfoPath: {
				id: '',
				name: '',
				address: '',
				code: '',
				startKM: 0,
				startM: 0,
				endKM: 0,
				endM: 0,
				planLength: 0,
				buildLength: 0,
				checkLength: 0,
				betonLength: 0,
				explains: '',
				defects: [],
        checkLines: [],
        suiDaoDividerInfo: []
      },
            
    };
  },
  props: [],
  created() {
			window[this.Id] =  this;
  },
  methods: {

      GetSevens(){
				this.$api.request({ Vue: this, apiName: 'checkOver', query: { tempId: dataV.Page.CurrentTunnelId, datatype: 2 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('sevens', json)
					} else {
						alert(response.message)
					}
				})
			},

      GetMonths(){
				this.$api.request({ Vue: this, apiName: 'fixOver', query: { tempId: dataV.Page.CurrentTunnelId, datatype: 2 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('months', json)
					} else {
						alert(response.message)
					}
				})
			},
      
  },
  watch: {
    Name(){
      this.tempTunnelInfoPath.id = '',
      this.tempTunnelInfoPath.name = this.ModelData.TunnelName,									// 名称
      this.tempTunnelInfoPath.address = this.ModelData.TunnelAddress,								// 地点
      this.tempTunnelInfoPath.code = this.ModelData.TunnelCode,
      this.tempTunnelInfoPath.startKM = this.ModelData.TunnelStartKiloMetre,
      this.tempTunnelInfoPath.startM = this.ModelData.TunnelStartMetre,
      this.tempTunnelInfoPath.endKM = this.ModelData.TunnelEndKiloMetre,
      this.tempTunnelInfoPath.endM = this.ModelData.TunnelEndMetre,
      this.tempTunnelInfoPath.planLength = this.DesignLength,
      this.tempTunnelInfoPath.buildLength = this.BuildLength,
      this.tempTunnelInfoPath.checkLength = this.TestLength,
      this.tempTunnelInfoPath.betonLength = this.CrackLength,
      this.tempTunnelInfoPath.explains = '',
      this.tempTunnelInfoPath.defects = this.ModelData.bugs,
      this.tempTunnelInfoPath.checkLines = this.ModelData.checkLines,
      this.tempTunnelInfoPath.suiDaoDividerInfo = this.ModelData.SuiDaoDividerInfo
      
      window.dataV.CurrentTunnelInfo = this.tempTunnelInfoPath
    }
  },
  computed: {
    _TotalFaultCount(){
      let t = this.FaultCount1 + this.FaultCount2 + this.FaultCount3 + this.FaultCount4
      if(t == 0) t = 1
      return t
    }
  },
  mounted() {
    // 注册弹出窗口
  },
  components: {
    "dv-img": dvImg,
    'dv-smalltunnel': dvSmallTunnel
  },
};
</script>
