import _Vue from 'vue'
import App from './App.vue'
import VueAxiosPlugin from 'vue-axios-plugin'
import api from "./api/index";
import $ from 'jquery'
import vuescroll from 'vuescroll'
import 'vuescroll/dist/vuescroll.css'
import dataV from './bin/dataV'
import qs from 'qs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
_Vue.use(ElementUI);
const querys = qs.parse(window.location.href.split('?')[1])

window.ApiData = {
  token: querys['token'],
  kt: querys['kt']
};

_Vue.config.productionTip = false

_Vue.use(VueAxiosPlugin, {
  reqHandleFunc: config => {
    config.headers['X-Token'] = window.ApiData.token
    return config
  },
  reqErrorFunc: error => Promise.reject(error),
  resHandleFunc: response => {
    return response.data
  },
  resErrorFunc: error => {
    $('#app').html('服务器响应失败，请联系管理员！');
    Promise.reject(error)
  }
}).use(api).use(vuescroll)

const Vue = new _Vue({ render: h => h(App) })
const loadVueUI = () => { Vue.$mount('#app') }
let ApiErr = false;
const showInfo = true;

let Funs = [];
if (window.ApiData.token) Funs = Funs.concat([
  { key: 'screen', apiName: 'screen' },
  { key: 'userRoot', apiName: 'userRoot' }
])
else {
  ApiErr = true
  $('#app').html('<div style="font-size: 18px">请输入正确的访问码！</div>')
}

let FunIndex = -1;
const getApiData = () => {
  if (!Funs[FunIndex + 1]) {
    if (!ApiErr) loadVueUI()
    return;
  }
  FunIndex++
  const params = Funs[FunIndex]
  if (showInfo) $('#app').append('<div id="' + params.apiName + '" style="font-size: 18px">[' + new Date().toLocaleString() + '] 获取 ' + params.apiName + ' => <span></span></div>');
  Vue.$api.request(Object.assign({ Vue }, params)).then(response => {
    if (response.state === 'success') {
      if (showInfo) $('#app #' + params.apiName + ' span').html('成功')
      if (params.cb) {
        params.cb(response.data)
      } else {
        window.ApiData[params.key] = (() => { try { return JSON.parse(response.data) } catch (e) { return response.data } })()
      }
    } else {
      if (showInfo) $('#app #' + params.apiName + ' span').html('失败：' + response.message)
      ApiErr = true
      window.ApiData[params.key] = (() => { try { return JSON.parse(response.data) } catch (e) { return response.data } })()
    }
    getApiData()
  })
}

getApiData()

// 键盘操作，主要针对web3D窗口，将焦点转移到隧道模型窗口上，并增加部分键盘操作
window.addEventListener('keydown', function (e) {
  // 当按下回车键时，打开语音模拟输入窗口

  // 其他按钮响应：全屏、模型回到初始位置
  const keyValue = e.code || dataV.Config.KEYCODE_TO_CODE[e.keyCode];
  if (dataV.Config.KEYS.indexOf(keyValue) !== -1) {
    $('#dv-map .vrbrowser iframe').focus();
  }
}, false);
