<style scoped>
    .hide { display: none; }

    .dv-bottom-tunnel { position: absolute; bottom: -800px; left: 500px; pointer-events: none; }

    .dv-bottom-tunnel .dataList { position: absolute; left: 0; bottom: -20px; width: 750px; height: 900px; overflow: hidden; }

    /* 项目数据列表 */
    .dv-bottom-tunnel .dataList .bg-6{ position: absolute; left: 0; top: 530px; width: 820px; height: 36px; }
    .dv-bottom-tunnel .dataList .bg-14{ position: absolute; left: 0; top: 562px; width: 820px; height: 8px; }
    .dv-bottom-tunnel .dataList .pageBtns{ position: absolute; left: 10px; top: 505px; width: 730px; text-align: right; }
    .dv-bottom-tunnel .dataList .pageBtns .pagebtn{ margin: 0 3px; cursor: pointer; pointer-events: all; }

    .dv-bottom-tunnel .dataList .pageBtns.numBtns div{ display: inline-block; color: #00FFFF; cursor: pointer; pointer-events: all; }
    .dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(4){
        cursor: pointer;
        transform:rotate(180deg);
        -ms-transform:rotate(180deg); 	/* IE 9 */
        -moz-transform:rotate(180deg); 	/* Firefox */
        -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
        -o-transform:rotate(180deg); 	/* Opera */
    }
    .dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(4).rotate{ 
        transform:rotate(0deg);
        -ms-transform:rotate(0deg); 	/* IE 9 */
        -moz-transform:rotate(0deg); 	/* Firefox */
        -webkit-transform:rotate(0deg); /* Safari 和 Chrome */
        -o-transform:rotate(0deg); 	/* Opera */
    }
    .dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(5){ opacity: 0; display: none; position: absolute; left: 110px; top: 20px; width: 680px; border: 1px solid #0135AC; background-color: #041A3F; padding: 5px; z-index: 5; text-align: left; border-radius: 5px; }
    .dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(5) span{ display: inline-block; width: 30px; height: 30px; line-height: 30px; overflow: hidden; border: 1px solid white; margin: 1px; text-align: center; cursor: pointer; }
    .dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(5) span:hover{ background-color: #0048FE; }
    .dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(5) span.current{ background-color: #44991A; color: white; }

    .dv-bottom-tunnel .dataList .labels { position: absolute; left: 0; top: 6px; width: 850px; }
    .dv-bottom-tunnel .dataList .labels .item-5{ position: absolute; left: 22px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-tunnel .dataList .labels .item-6{ position: absolute; left: 70px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-tunnel .dataList .labels .item-7{ position: absolute; left: 270px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .labels .item-8{ position: absolute; left: 390px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .labels .item-9{ position: absolute; left: 510px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .labels .item-10{ position: absolute; left: 630px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .labels .item-11{ position: absolute; left: 767px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-tunnel .dataList .pageList { position: absolute; left: -10px; top:570px; width: 830px; font-size: 15px; height: 450px; overflow: hidden; }
    .dv-bottom-tunnel .dataList .pageList .row { position: absolute; left: 30px; width: 820px; }
    .dv-bottom-tunnel .dataList .pageList .row.repaired { color: green }
    .dv-bottom-tunnel .dataList .pageList .row .bg-line{ position: absolute; left: -22px; top: 0; width: 822px; height: 26px; }
    .dv-bottom-tunnel .dataList .pageList .row div { font-weight: normal; cursor: pointer; font-family: "microsoft yahei"; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(1){ position: absolute; left: -15px; top: 3px; width: 60px; text-align: center; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(2){ position: absolute; left: 50px; top: 3px; font-family: 'Fjalla One', sans-serif; cursor: pointer; pointer-events: all; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(3){ position: absolute; left: 250px; top: 3px; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(4){ position: absolute; left: 370px; top: 3px; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(5){ position: absolute; left: 490px; top: 3px; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(6){ position: absolute; left: 610px; top: 3px; width: 120px; text-align: center; }
    .dv-bottom-tunnel .dataList .pageList .row div:nth-of-type(7){ position: absolute; left: 730px; top: 3px; width: 60px; text-align: center; }

    .dv-bottom-tunnel .chartsBox { position: absolute; left: 760px; bottom: 375px; font-weight: bold; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }
    .dv-bottom-tunnel .chartsBox .bg02{ position: absolute; left:     0; top:     0; width: 305px; height: 180px; }
    .dv-bottom-tunnel .chartsBox .bg03{ position: absolute; left: 309px; top:     0; width: 305px; height: 180px; }
    .dv-bottom-tunnel .chartsBox .bg04{ position: absolute; left:     0; top: 183px; width: 305px; height: 180px; }
    .dv-bottom-tunnel .chartsBox .bg05{ position: absolute; left: 309px; top: 183px; width: 305px; height: 180px; }
 
    .dv-bottom-tunnel .chartsBox .bg02-title{ position: absolute; left:   5px; top:   5px; }
    .dv-bottom-tunnel .chartsBox .bg04-title{ position: absolute; left: 314px; top:   5px; }
    .dv-bottom-tunnel .chartsBox .bg05-title{ position: absolute; left:   5px; top: 188px; }
    .dv-bottom-tunnel .chartsBox .bg03-title{ position: absolute; left: 314px; top: 188px; }
    .dv-bottom-tunnel .chartsBox .label_trend_01{ position: absolute; left:  19px; top:   6px; width: 200px; }
    .dv-bottom-tunnel .chartsBox .label_trend_03{ position: absolute; left: 328px; top:   6px; width: 200px; }
    .dv-bottom-tunnel .chartsBox .label_trend_04{ position: absolute; left:  19px; top: 189px; width: 200px; }
    .dv-bottom-tunnel .chartsBox .label_trend_02{ position: absolute; left: 328px; top: 189px; width: 200px; }
 
    .dv-bottom-tunnel .chartsBox .echarts #chart_p4_01{ position: absolute; left:   5px; top:  30px; width: 295px; height: 140px; }
    .dv-bottom-tunnel .chartsBox .echarts #chart_p4_03{ position: absolute; left: 314px; top:  30px; width: 295px; height: 140px; }
    .dv-bottom-tunnel .chartsBox .echarts #chart_p4_04{ position: absolute; left:   5px; top: 213px; width: 295px; height: 140px; }
    .dv-bottom-tunnel .chartsBox .echarts #chart_p4_02{ position: absolute; left: 314px; top: 213px; width: 295px; height: 140px; }

</style>

<template>
    <div class="dv-bottom-tunnel">
                
        <div class="dataList">
            <dv-img class="bg-6" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
            <dv-img class="bg-14" src="imgs/p2/line.png"></dv-img>
            <div class="labels">
                <div class="item-5">序号</div>
                <div class="item-6">检测里程</div>
                <div class="item-7">缺陷位置</div>
                <div class="item-8">缺陷长度（M）</div>
                <div class="item-9" v-text="getDefectColumnNames[0]"></div>
                <div class="item-10" v-text="getDefectColumnNames[1]"></div>
                <div class="item-11">备注</div>
            </div>
            <div class="pageBtns" v-if="(Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0)) <= maxPage">  
                <dv-img class="pagebtn" v-for="pageIndex in (Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0))" :key="'page_btn_' + pageIndex" @mouseover="selectPage" :data-index="pageIndex" :title="pageIndex" :src="'imgs/p2/' + (currentPage == pageIndex ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'"></dv-img>
            </div>
            <div class="pageBtns numBtns" v-if="(Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0)) > maxPage">
                <div v-text="currentPage"></div>
                <div>/</div>
                <div v-text="(Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0))"></div>
                <div @click="togglePagePanel">▲</div>
                <div>
                    <span v-for="pageIndex in (Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0))" :key="'page_num_' + pageIndex" :class="pageIndex == currentPage ? 'current' : ''" v-text="pageIndex" @click="clickPageNumber" :data-index="pageIndex"></span>
                </div>
            </div>
            <div class="pageList" :style="'height: ' + pagesize * 30 + 'px'" @mouseover="pageMouseOver" @mouseleave="pageMouseLeave">
                <div v-for="(item, index) in defects" :class="'row' + (item.repaired ? ' repaired' : '')" :style="'top: ' + (0 + 30 * (index)) + 'px'" :key="'defectsList_' + index" :title="item.id" @click="clickDefect">
                    <dv-img class="bg-line" src="imgs/p5/20200616133308.png"></dv-img>
                    <div v-text="index + 1"></div>
                    <div v-text="tunnelInfoPatch.code + item.startKiloMetre + '+' + item.startMetre.toFixed(1) + '-' + tunnelInfoPatch.code + (item.startKiloMetre + Math.floor((item.startMetre + item.length) / 1000)) + '+' + ((item.startMetre + item.length) % 1000).toFixed(1)"></div>
                    <div v-text="item.position"></div>
                    <div v-text="item.length"></div>
                    <div v-text="item.info_01"></div>
                    <div v-text="item.info_02"></div>
                    <div v-html="(item.repaired ? '已整改' : (currentDefect != '混凝土强度' ? item.info_03 : (item.isbug ? '' : '合格')))"></div>
                </div>
                <div class="row" v-for="index in (defects.length < pagesize ? pagesize - defects.length : 0)" :key="'defectsList_bg_' + index" :style="'top: ' + (0 + 30 * (index + defects.length - 1)) + 'px'">
                    <dv-img class="bg-line" src="imgs/p5/20200616133308.png"></dv-img>
                </div>
            </div>
        </div>

        <div class="chartsBox">
            <dv-img class="bg02" src="imgs/tunnel/图表边框.png"></dv-img>
            <dv-img class="bg03" src="imgs/tunnel/图表边框.png"></dv-img>
            <dv-img class="bg04" src="imgs/tunnel/图表边框.png"></dv-img>
            <dv-img class="bg05" src="imgs/tunnel/图表边框.png"></dv-img>
            <dv-img class="bg02-title" src="imgs/p3/f7522c50-0cbe-4384-b554-fdec0c1bfde5.png"></dv-img>
            <dv-img class="bg03-title" src="imgs/p3/6707e5e0-5929-4452-b4e5-684c4199da79.png"></dv-img>
            <dv-img class="bg04-title" src="imgs/p3/48ce1d6c-2b7e-427a-bf12-bf5ccf25d2b8.png"></dv-img>
            <dv-img class="bg05-title" src="imgs/p3/f09ce625-c05b-4e73-a318-a0b95a72b81f.png"></dv-img>
            <div class="label_trend_01">衬砌厚度不足发展趋势</div>
            <div class="label_trend_02">空洞、不密实问题发展趋势</div>
            <div class="label_trend_03">钢筋缺陷问题发展趋势</div>
            <div class="label_trend_04">防裂钢筋网问题发展趋势</div>
            <div class="echarts">
                <div id="chart_p4_01"></div>
                <div id="chart_p4_02"></div>
                <div id="chart_p4_03"></div>
                <div id="chart_p4_04"></div>
            </div>
        </div>

    </div>
</template>

<script>
import * as $ from 'jquery'
import * as echarts from 'echarts'
import dvImgVue from "./dv-img.vue"
import dataV from '../../bin/dataV'

export default {
    data () {
        return {
            Id: 'BottomTunnelComponent',
            pagesize: 10,				// 项目、隧道、缺陷列表显示记录数
            currentPage: 1,				// 项目、隧道、缺陷列表当前页码
            currentPageTimer: null,
            contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
            pageToggleKey: true,		// 是否允许列表页自动翻页
            maxPage: dataV.Config.initPage.maxPage,

            currentPageLevel: dataV.Config.initPage.currentPageLevel,

            echartsTimer: null,
            charts: {},

            WindowStatus: {
                isPagePanelOpen: false,
            },

            BuildLength: 0,
            CrackLength: 0,
            DesignLength: 0,
            FaultCount1: 0,
            FaultCount2: 0,
            FaultCount3: 0,
            FaultCount4: 0,
            FaultCount5: 0,
            FaultCount6: 0,
            FaultDetailList1: [],
            FaultDetailList2: [],
            FaultDetailList3: [],
            FaultDetailList4: [],
            FaultRate1: 0,
            FaultRate2: 0,
            FaultRate3: 0,
            FaultRate4: 0,
            FaultRate5: 0,
            FaultRate6: 0,
            FaultTrend1: [],
            FaultTrend2: [],
            FaultTrend3: [],
            FaultTrend4: [],
            ModelData: {
                GoodKiloMetreInfo: null,
                OverKiloMetreInfo: {startKiloMetre: 99, startMetre: 482, endKiloMetre: 99, endMetre: 818},
                ProjectDepartment: "湖杭铁路项目经理部二分部",
                ProjectName: "湖杭铁路二分部",
                ProjectType: "铁路",
                SuiDaoDividerInfo: [],
                TunnelAddress: "浙江省富阳市",
                TunnelCode: "DK",
                TunnelEndKiloMetre: 0,
                TunnelEndMetre: 0,
                TunnelName: "羊岩坞二号隧道出口",
                TunnelStartKiloMetre: 0,
                TunnelStartMetre: 0,
                ViewKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
                WorkKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
                bugs: [],
                checkLines: [],
            },
            Name: "",
            PileNumCode: "",
            ProjectSimpleInfo: {BelongCategory: "", SiteName: "", Address: ""},
            TestLength: 0,
            TestRate: 0,
            TotalFaultBadCount: 0,
            TotalFaultBadFixRate: 0,
            TotalFaultBadRate: 0,
            TotalFaultCount: 0,
            TotalFaultFixRate: 0,
            TotalFaultRate: 0,

            currentDefect: dataV.Config.initPage.defect,    
        }
    },
    components: {
        'dv-img': dvImgVue,
    },
    created() {
        window[this.Id] =  this;
    },
    methods: {

        sync(){
            this.currentPageLevel = dataV.Page.CurrentLevel
        },

        toggleDefect: function(e) {
            // dataV.Page.Toggle(this, 3, dataV.Page.CurrentTunnelId)
            this.currentDefect = typeof(e) === 'string' ? e : e.target.title;
        },
                    
        clickDefect(){
            var defectID = $(event.target).offsetParent().attr('title');
            if(dataV.WebVR) dataV.WebVR.lookAtDefect(defectID);
        },

        clickPageNumber(){
            this.selectPage();
            this.togglePagePanel();
        },

        selectPage(){		// 数据列表，手动换页
            this.currentPage = $(event.target).data('index');
            this.changePage(this.defects.length);
        },

        togglePagePanel(){
            var $pageBtn = $('.dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(4)');
            var $pagePanel = $('.dv-bottom-tunnel .dataList .pageBtns.numBtns div:nth-child(5)');
            if(!this.WindowStatus.isPagePanelOpen){
                this.WindowStatus.isPagePanelOpen = true;
                $pageBtn.addClass('rotate');
                $pagePanel.css({
                opacity: 0
                }).show().animate({
                opacity: 1
                }, 500)
            }else{
                this.WindowStatus.isPagePanelOpen = false;
                $pageBtn.removeClass('rotate');
                $pagePanel.animate({
                opacity: 0
                }, 500, function(){
                $pagePanel.hide()
                })
            }
        },


        changePage(dataLength){
            var that = this;
            //
            if(this.currentPageTimer) clearInterval(this.currentPageTimer);
            var defectPageCount = Math.floor(dataLength / this.pagesize) + (dataLength % this.pagesize > 0 ? 1 : 0);
            if(defectPageCount > 1) {
                if(this.currentPageTimer) {
                    clearInterval(this.currentPageTimer)
                    this.currentPageTimer = null
                }
                this.currentPageTimer = setInterval(function(){
                    if(that.pageToggleKey){
                        var newpage = that.currentPage + 1;
                        if(newpage > defectPageCount) newpage = 1;
                        that.currentPage = newpage;
                    }
                }, 5000);
            }
        },

        toggleContrastBtn(){
            this.contrastIndex = $(event.target).data('index'); 
        },
        pageMouseOver(){
            this.pageToggleKey = false;
        },
        pageMouseLeave(){
            this.pageToggleKey = true;
        },

        p2trend(){
            var that = this;
            //      
            if(!this.charts['chart_011']) this.charts['chart_011'] = echarts.init(document.getElementById('chart_p4_01'));
            this.charts['chart_011'].setOption({
                tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
                xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend1.map(item => item.Name) },
                yAxis: { show: true, type: 'value', splitLine: { show: false } },
                series: [
                    { name: '衬砌厚度不足', type: 'line', data: that.FaultTrend1.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#b30306", width: 1 }, areaStyle: { color: "#b30306", opacity: 0.3 } }
                ]
            });
            if(!this.charts['chart_022']) this.charts['chart_022'] = echarts.init(document.getElementById('chart_p4_02'));
            this.charts['chart_022'].setOption({
                tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
                xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend2.map(item => item.Name) },
                yAxis: { show: true, type: 'value', splitLine: { show: false } },
                series: [
                    { name: '空洞、不密实', type: 'line', data: that.FaultTrend4.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#33CBE9", width: 1 }, areaStyle: { color: "#33CBE9", opacity: 0.3 } }
                ]
            });
            if(!this.charts['chart_033']) this.charts['chart_033'] = echarts.init(document.getElementById('chart_p4_03'));
            this.charts['chart_033'].setOption({
                tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
                xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend3.map(item => item.Name) },
                yAxis: { show: true, type: 'value', splitLine: { show: false } },
                series: [
                    { name: '钢筋缺失问题', type: 'line', data: that.FaultTrend2.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#FFBC09", width: 1 }, areaStyle: { color: "#FFBC09", opacity: 0.3 } }
                ]
            });
            if(!this.charts['chart_044']) this.charts['chart_044'] = echarts.init(document.getElementById('chart_p4_04'));
            this.charts['chart_044'].setOption({
                tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
                xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend4.map(item => item.Name) },
                yAxis: { show: true, type: 'value', splitLine: { show: false } },
                series: [
                    { name: '防裂钢筋网问题', type: 'line', data: that.FaultTrend3.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#ffffff", width: 1 }, areaStyle: { color: "#ffffff", opacity: 0.3 } }
                ]
            });

            // 轮循tooltip
            if(this.echartsTimer) clearInterval(this.echartsTimer)
            var index = 0; //播放所在下标
            this.echartsTimer = setInterval(function() {
                that.charts['chart_011'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
                that.charts['chart_022'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
                that.charts['chart_033'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
                that.charts['chart_044'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
                index++;
                if(index >= that.FaultTrend4.length) {
                    index = 0;
                }
            }, 3000);

        },

    },
    computed: {
        getDefectColumnNames(){
            var rv = null
            switch(this.currentDefect){
                case '衬砌厚度不足':
                rv = ['设计厚度（CM）', '实测厚度（CM）'];
                break;
                case '钢筋缺失问题':
                rv = ['设计数量（根）', '实测数量（根）'];
                break;
                case '其他问题':
                rv = ['缺陷类型', '距表面深度（CM）']
                break;
                case '防裂钢筋网问题':
                rv = ['设计值', '实测值']
                break;
                default:
                rv = ['设计值', '实测值']
            }
            return rv
        },
        
        tunnelInfoPatch(){
        // console.log('hehe: ', this.ModelData.TunnelStartMetre)
        return {
            id: '',
            name: this.ModelData.TunnelName,									// 名称
            address: this.ModelData.TunnelAddress,								// 地点
            code: this.ModelData.TunnelCode,
            startKM: this.ModelData.TunnelStartKiloMetre,
            startM: this.ModelData.TunnelStartMetre,
            endKM: this.ModelData.TunnelEndKiloMetre,
            endM: this.ModelData.TunnelEndMetre,
            planLength: this.DesignLength,
            buildLength: this.BuildLength,
            checkLength: this.TestLength,
            betonLength: this.CrackLength,
            explains: '',
            defects: this.ModelData.bugs,
            checkLines: this.ModelData.checkLines,
            suiDaoDividerInfo: this.ModelData.SuiDaoDividerInfo
        }
        },

        defects(){
            return this.tunnelInfoPatch.defects.filter((item)=>{ return item.type == this.currentDefect })
        },
    },
    watch: {

        currentPageLevel(){
            if(this.currentPageLevel != 3) this.currentDefect = dataV.Config.initPage.defect
        },

        currentPage(){
            const targetTop = (this.currentPage - 1) * this.pagesize * 30;
            $('.dv-bottom-tunnel .dataList .pageList').animate({
                scrollTop: targetTop + 'px'
            }, 'slow');
        },

        TunnelCompareFault(){
            this.changePage(this.defects.length);
        },

        FaultTrend1() {
            this.p2trend()
        }

    },

};
</script>