const Config = {
	TrainName: '川藏铁路',
	AreaName: '湖杭六标项目',
	AreaDescription: '　　中铁十二局集团有限公司承担新建湖州至杭州西至杭黄高铁连接线站前工程HHLJXZQ-6标，起讫里程为DK88+331.17～DK127+698.47，新建线路长39.135km。另外包括预留3、4线同步实施工程，新建线路长0.373公里。工程地点位于浙江省杭州市余杭区、富阳区境内。项目于2019年11月14日中标，中标金额28.36亿元，中标工期42个月；按照沪昆客专浙江公司上场推进会要求，本项目需在2022年7月底开通运营，实际工期仅33个月。',
	adminhref: 'https://czall.sxaliyun.com/Manage/VerificationToken?token=',
	keyWorks: [					// 重点工作列表
		{ title: '一个短期目标', content: '二公司项目部隧道新浇筑混凝 土衬砌雷达检测全覆盖；' },
		{ title: '两个实际作用', content: '监督、控制工程在建实体工程质量；指导工程施工工艺提高施工队伍的工作能力；' },
		{ title: '三级管理制度', content: '第一级工程检测中心职能部门统一标准，统一尺度，统一格式，第二级区域工程检测部、第三级项目工程检测部严格执行；' },
		{ title: '检测终极目标', content: '工程检测中心检测工程实体质量结果中质量问题越来越少，直至为0。' },
	],

	PresetParts: [
		{
			title: '一号预制件', defects: [
				{ name: '空洞', explain: '由于断面超挖、施工工艺等原因，造成衬砌混凝土和围岩以及初期支护、二衬混凝土之间或二衬混凝土层间存在空隙' },
				{ name: '不密实', explain: '因振捣不够、漏浆或混凝土离析等造成的蜂窝状、松散状以及遭受意外损伤所产生的疏松状混凝土区域' },
				{ name: '厚度不足', explain: '隧道开挖断面未达到设计标准，造成衬砌混凝土厚度不足' },
				{ name: '钢筋缺陷', explain: '区段内钢筋数量少于设计数量' },
				{ name: '7天超时确认', explain: '隧道雷达监测结果要求在7天内确认检测内容是否正确' },
				{ name: '30天超时闭合', explain: '经过确认的雷达监测结果需要在确认后30天内完成整改，形成缺陷整改闭合流程' },
			]
		},
		{
			title: '二号预制件', defects: [
				{ name: '空洞', explain: '由于断面超挖、施工工艺等原因，造成衬砌混凝土和围岩以及初期支护、二衬混凝土之间或二衬混凝土层间存在空隙' },
				{ name: '不密实', explain: '因振捣不够、漏浆或混凝土离析等造成的蜂窝状、松散状以及遭受意外损伤所产生的疏松状混凝土区域' },
				{ name: '厚度不足', explain: '隧道开挖断面未达到设计标准，造成衬砌混凝土厚度不足' },
				{ name: '钢筋缺陷', explain: '区段内钢筋数量少于设计数量' },
				{ name: '7天超时确认', explain: '隧道雷达监测结果要求在7天内确认检测内容是否正确' },
				{ name: '30天超时闭合', explain: '经过确认的雷达监测结果需要在确认后30天内完成整改，形成缺陷整改闭合流程' },
			]
		},
	],


	windowAutoResize: false,
	windowWidth() {
		if (this.windowAutoResize) {
			return window.innerWidth;
		} else {
			return 1920;
		}
	},
	windowHeight() {
		if (this.windowAutoResize) {
			return window.innerHeight;
		} else {
			return 1080;
		}
	},

	URLs: {
		dataView: '/src/dataView/',
		vueComponents: '/js/animates/pages/components/',
		threejs: '/threejs/'
	},

	baseUrl: {
		api: 'https://czall.sxaliyun.com',
		img: 'https://czall.sxaliyun.com',
	},

	vr: {
		url: '/3D/index.html',
		allow2Scale: false,									// 是否支持在大屏中放大VR 或 进入VR模式
	},

	originalSystem: {
		url: 'https://jczx169.sxaliyun.cn/',
		// url: '/aframe/examples/likeus/test01/',
		report_root: 'http://cr122-report.cltc.info/#/',
		file_root: 'http://39.106.44.187:8899/files/',
	},

	animate: {
		video: {
			x: 0
		}
	},

	onlyRailways: true,

	zIndex: {
		video: 10000,				// 视频
		mask: 200,					// 蒙板
		staff: 5000,				// 员工信息面板

	},

	initPage: {		// 大屏页面初始化时的位置
		level: 0,
		area: '东北区域',
		program: '工程检测中心',
		tunnel: '工程检测中心案例隧道',
		tunnelId: 'facd6ddf-6e05-4348-a2f2-03aa2260b814',		// 工程检测中心案例隧道
		defect: '衬砌厚度不足',
		maxPage: 60,
	},

	colors: {
		defect: {
			'衬砌厚度不足': '#b30306',
			'钢筋缺失问题': '#FFBC09',
			'其他问题': '#33CBE9',
			'防裂钢筋网问题': '#9094A0',
		}
	},

	typeToZH: {
		railways: '铁路',
		highways: '公路',
		subways: '市政',
		waterways: '水利'
	},

	voice: {
		mp3: true
	},

	sound: {
		bg: false
	},

	KEYS: [
		'KeyW', 'KeyA', 'KeyS', 'KeyD',
		'KeyI', 'KeyJ', 'KeyK', 'KeyL',
		'ArrowUp', 'ArrowLeft', 'ArrowRight', 'ArrowDown'
	],

	KEYCODE_TO_CODE: {
		'38': 'ArrowUp',
		'37': 'ArrowLeft',
		'40': 'ArrowDown',
		'39': 'ArrowRight',
		'87': 'KeyW',
		'65': 'KeyA',
		'83': 'KeyS',
		'68': 'KeyD',
		'73': 'KeyI',
		'74': 'KeyJ',
		'75': 'KeyK',
		'76': 'KeyL',
		'72': 'KeyH',
		'86': 'KeyV',
	},

}
export default Config



