<style>
  #dv-right-index { position: absolute; right: -1100px; top: 0; width: 560px; }
  #dv-right-index .time { position: absolute; right: 45px; top: 50px; width: 400px; text-align: center; font-size: 18px; font-weight: 600; font-style: normal; color: rgb(0, 112, 216); }

  #p1 .bg-1{ position: absolute; right: 45px; top: 100px; }
  /* #p1 .bg-2{ position: absolute; right: 45px; top: 200px; } */

  /* 各类总长 */
  #p1 .bg-31{ position: absolute; left: 125px; top: 315px; }
  #p1 .bg-34{ position: absolute; left: 125px; top: 448px; }
  #p1 .bg-32{ position: absolute; left: 256px; top: 315px; }
  #p1 .bg-35{ position: absolute; left: 256px; top: 448px; }
  #p1 .bg-33{ position: absolute; left: 387px; top: 315px; }
  #p1 .bg-36{ position: absolute; left: 387px; top: 448px; }

  #p1 .bg-41{ position: absolute; left: 170px; top: 335px; }
  #p1 .bg-42{ position: absolute; left: 300px; top: 335px; }
  #p1 .bg-43{ position: absolute; left: 432px; top: 335px; }
  #p1 .bg-44{ position: absolute; left: 170px; top: 468px; }
  #p1 .bg-45{ position: absolute; left: 300px; top: 468px; }
  #p1 .bg-46{ position: absolute; left: 432px; top: 468px; }
  
  /* 整改合格率 */
  #p1 .bg-3{ position: absolute; left: 125px; top: 590px; width: 389px; height: 190px; }	

  /* 雷达图 */
  #p1 .bg-37{ position: absolute; left: 125px; top: 810px; width: 389px; }

  /* 以下是文字 */

  #dv-right-index .text{ position: absolute; left: 115px; top: 93px; font-size: 16px; font-weight: bold; width: 810px; }
  #dv-right-index .text span{ color: #00ffff; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }/* 'Teko' */

  #p1 .text .item-101{ position: absolute; left: 30px; top:   14px; font-size: 22px; line-height: 170%; }
  #p1 .text .item-101 span{ font-size: 40px; }
  #p1 .text .item-102{ position: absolute; left: 30px; top:  124px; font-size: 22px; line-height: 170%; }
  #p1 .text .item-102 span{ font-size: 40px; }
  #p1 .text .item-103{ position: absolute; left: 200px; top:  14px; font-size: 22px; }
  #p1 .text .item-104{ position: absolute; left: 295px; top:  14px; font-size: 22px; }
  #p1 .text .item-105{ position: absolute; left: 200px; top:  60px; font-size: 22px; }
  #p1 .text .item-106{ position: absolute; left: 295px; top:  60px; font-size: 22px; }
  #p1 .text .item-107{ position: absolute; left: 200px; top: 124px; font-size: 22px; }
  #p1 .text .item-108{ position: absolute; left: 295px; top: 124px; font-size: 22px; }
  #p1 .text .item-109{ position: absolute; left: 200px; top: 170px; font-size: 22px; }
  #p1 .text .item-110{ position: absolute; left: 295px; top: 170px; font-size: 22px; }

  /* 各类总长 */
  #p1 .text .item-61{ position: absolute; left:  10px; top: 285px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-71{ position: absolute; left:  10px; top: 310px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-62{ position: absolute; left: 140px; top: 285px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-72{ position: absolute; left: 140px; top: 310px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-63{ position: absolute; left: 272px; top: 285px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-73{ position: absolute; left: 272px; top: 310px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-64{ position: absolute; left:  10px; top: 418px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-74{ position: absolute; left:  10px; top: 443px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-65{ position: absolute; left: 140px; top: 418px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-75{ position: absolute; left: 140px; top: 443px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-66{ position: absolute; left: 272px; top: 418px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-76{ position: absolute; left: 272px; top: 443px; font-size: 17px; width: 125px; text-align: center; }

  /* 整改合格率 */
  #p1 .text .item-51{ position: absolute; left:  30px; top: 517px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-52{ position: absolute; left: 220px; top: 537px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-53{ position: absolute; left:  30px; top: 615px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-54{ position: absolute; left: 110px; top: 615px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-55{ position: absolute; left: 220px; top: 615px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-56{ position: absolute; left: 300px; top: 615px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-21{ position: absolute; left:  30px; top: 537px; font-size: 44px; }
  #p1 .text .item-22{ position: absolute; left: 220px; top: 560px; font-size: 22px; }
  #p1 .text .item-23{ position: absolute; left:  30px; top: 645px; font-size: 28px; }
  #p1 .text .item-24{ position: absolute; left: 110px; top: 645px; font-size: 28px; }
  #p1 .text .item-25{ position: absolute; left: 220px; top: 645px; font-size: 28px; }
  #p1 .text .item-26{ position: absolute; left: 300px; top: 645px; font-size: 28px; }

  /* 雷达图 */
  #p1 .text .item-81{ position: absolute; left:  37px; top: 740px; font-size: 12px; width: 125px; font-style: normal; text-align: center; }
  #p1 .text .item-82{ position: absolute; left:  15px; top: 807px; font-size: 12px; width: 30px; font-style: normal; text-align: center; }
  #p1 .text .item-83{ position: absolute; left: 157px; top: 807px; font-size: 12px; width: 30px; font-style: normal; text-align: center; }
  #p1 .text .item-84{ position: absolute; left:  37px; top: 885px; font-size: 12px; width: 125px; font-style: normal; text-align: center; }

  #p1 .text .item-85 { position: absolute; left: 200px; top: 731px; font-size: 12px; width: 125px; font-style: normal; }

  #p1 .text .item-86 { position: absolute; left: 200px; top: 759px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .item-87 { position: absolute; left: 200px; top: 805px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .item-89 { position: absolute; left: 200px; top: 851px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .item-88 { position: absolute; left: 200px; top: 897px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .bar2d_01{ position: absolute; left: 200px; top: 782px; width: 150px; }
  #p1 .text .bar2d_02{ position: absolute; left: 200px; top: 828px; width: 150px; }
  #p1 .text .bar2d_04{ position: absolute; left: 200px; top: 874px; width: 150px; }
  #p1 .text .bar2d_03{ position: absolute; left: 200px; top: 920px; width: 150px; }
  #p1 .text .item-91 { position: absolute; left: 352px; top: 778px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }
  #p1 .text .item-92 { position: absolute; left: 352px; top: 824px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }
  #p1 .text .item-94 { position: absolute; left: 352px; top: 870px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }
  #p1 .text .item-93 { position: absolute; left: 352px; top: 916px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }

	#p1 .echarts #chart04{ position: absolute; left: 143px; top: 840px; width: 145px; height: 145px; }
.el-select>.el-input input {
  background-color: #061963;
  color: white;
  border: 1px #0150DD solid;
}
.el-select>.el-input input:hover{
  border: 1px #0150DD solid;
}
.el-select-dropdown{
  background-color:#061963 !important;
 
  border: 1px #0150DD solid !important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background-color: #0150DD !important;
}
.el-select-dropdown__item{
   color:white !important;
}
#dv-right-index .adminhref{
  width: 200px;
  height: 30px;
  position: absolute;
  z-index: 50;
  right:45px;
  top:1040px;
  line-height: 30px;
  
}
#dv-right-index .adminhref a{text-decoration: none;color: #6a6b7e; float: right;font-size: 14px;}
</style>

<template>
  <div id="dv-right-index">
    <i class="time" key="trans-02" style="z-index:5">
       <div>{{nowTime}}</div>
    </i>

    <div class="page" id="p1">
      <div class="bgs">
        <dv-img class="bg-1" src="imgs/p1/项目页-bg.png"></dv-img>
        <!-- <dv-img class="bg-2" src="imgs/p1/已监控项目-bg.png"></dv-img> -->
        
        <dv-img class="bg-3" src="imgs/p2/bae6b497-6209-4227-8443-7b63a1629994.png"></dv-img>
        
        <!-- 第二栏 -->
        <dv-img class="bg-31" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-32" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-33" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-34" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-35" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-36" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        
        <dv-img class="bg-37" src="imgs/p2/9c5b65e2-e170-4cfc-ae35-3f9665a7ad48.png"></dv-img>
        
        <dv-img class="bg-41" src="imgs/p2/34c3d01e-9939-4ccd-9815-c665b0c2b277.png"></dv-img>
        <dv-img class="bg-42" src="imgs/p2/7662f8c6-3814-4f39-bf80-59460734fd57.png"></dv-img>
        <dv-img class="bg-43" src="imgs/p2/41ba0cbe-5050-445d-b83b-0e16ee05cdf6.png"></dv-img>
        <dv-img class="bg-44" src="imgs/p2/b69c8cc1-ec6e-4825-8ade-4db7be554ac5.png"></dv-img>
        <dv-img class="bg-45" src="imgs/p2/5bbae94f-c50a-4a37-a805-306f0ad0cada.png"></dv-img>
        <dv-img class="bg-46" src="imgs/p2/54f99eb4-03c2-4eb9-ac62-8c4324254cfe.png"></dv-img>

      </div>
      <div class="text">
        <div class="item-101">分部<br><span v-text="MonitorTotalProjectCount">0</span></div>
        <div class="item-102">隧道<br><span v-text="MonitorTotalTunnelCount">0</span></div>
        <div class="item-103">已监控分部 <span v-text="MonitorProjectCount">0</span></div>
        <!-- <div class="item-104"><span v-text="MonitorProjectCount2">0</span></div> -->
        <div class="item-105">未监控分部 <span v-text="MonitorNotProjectCount">0</span></div>
        <!-- <div class="item-106"> <span v-text="MonitorProjectCount4">0</span></div> -->
        <div class="item-107">已监控隧道 <span v-text="MonitorTunnelCount">0</span></div>
        <!-- <div class="item-108"><span v-text="MonitorTunnelCount2">0</span></div> -->
        <div class="item-109">未监控隧道 <span v-text="MonitorNotTunnelCount">0</span></div>
        <!-- <div class="item-110"><span v-text="MonitorTunnelCount4">0</span></div> -->
        
        <div class="item-51">整改合格率</div>
        <div class="item-52">重大缺陷整改合格率</div>
        <div class="item-53">缺陷总数</div>
        <div class="item-54">重大缺陷总数</div>
        <div class="item-55">缺陷整改</div>
        <div class="item-56">重大缺陷整改</div>
        
        <div class="item-21"><span v-text="TotalFixRate + '%'"></span></div>
        <div class="item-22"><span v-text="TotalFixBadRate + '%'"></span></div>
        <div class="item-23"><span v-text="TotalFaultCount"></span></div>
        <div class="item-24"><span v-text="TotalFaultBadCount"></span></div>
        <div class="item-25"><span v-text="TotalFixCount"></span></div>
        <div class="item-26"><span v-text="TotalFixBadCount"></span></div>
        
        <!-- 第二栏 -->
        <div class="item-61">规划总长</div>
        <div class="item-62">衬砌总长</div>
        <div class="item-63">已检总长</div>
        <div class="item-64">已检覆盖率</div>
        <div class="item-65">缺陷率</div>
        <div class="item-66">重大缺陷率</div>
        <div class="item-71"><span v-text="DesignLength + 'KM'"></span></div>
        <div class="item-72"><span v-text="CrackLength + 'KM'"></span></div>
        <div class="item-73"><span v-text="TestLength + 'KM'"></span></div>
        <div class="item-74"><span v-text="TestRate + '%'"></span></div>
        <div class="item-75"><span v-text="TotalFaultRate + '%'"></span></div>
        <div class="item-76"><span v-text="TotalFaultBadRate + '%'"></span></div>
        
        <div class="item-81">厚度不足</div>
        <div class="item-82">钢筋缺失</div>
        <div class="item-83">空洞不密实</div>
        <div class="item-84">防裂钢筋网</div>
        <div class="item-85">缺陷数量</div>
        <div class="item-86">厚度不足</div>
        <div class="item-87">钢筋缺失</div>
        <div class="item-88">空洞、不密实</div>
        <div class="item-89">防裂钢筋网</div>
        
        <div class="item-91"><span v-text="FaultCount1"></span></div>
        <div class="item-92"><span v-text="FaultCount2"></span></div>
        <div class="item-93"><span v-text="FaultCount4"></span></div>
        <div class="item-94"><span v-text="FaultCount3"></span></div>
        
        <dv-bar-2d v-if="refreshBars" class="bar2d_01" height="12px" barcolorh="0" during="3"   :value="FaultCount1 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        <dv-bar-2d v-if="refreshBars" class="bar2d_02" height="12px" barcolorh="30" during="3"  :value="FaultCount2 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        <dv-bar-2d v-if="refreshBars" class="bar2d_03" height="12px" barcolorh="190" during="3" :value="FaultCount4 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        <dv-bar-2d v-if="refreshBars" class="bar2d_04" height="12px" barcolors="5%" during="3"  :value="FaultCount3 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>

      </div>
      
      <div class="echarts">
        <div id="chart04"></div>
      </div>
    </div>
      <div class="adminhref"><a :href="adminlj+token" target="_blank">后台管理登录</a></div>
  </div>
</template>

<script>
import * as $ from 'jquery'
import * as echarts from 'echarts'
import dvImg from "./dv-img.vue"
import dataV from '../../bin/dataV'
import dvBar2d from "./dv-bar-2d.vue"

export default {
  data() {
    return {
      Id: 'RightIndexComponent',
      testingOrgList: [],
      selectedTestingOrg: '',
      adminlj : dataV.Config.adminhref,
      token:window.ApiData.token,
      // CrackLength: window.ApiData.index.CrackLength,
      // DesignLength: window.ApiData.index.DesignLength,
      // FaultCount1: window.ApiData.index.FaultCount1,
      // FaultCount2: window.ApiData.index.FaultCount2,
      // FaultCount3: window.ApiData.index.FaultCount3,
      // FaultCount4: window.ApiData.index.FaultCount4,
      // MonitorProjectCount: window.ApiData.index.MonitorProjectCount,
      // MonitorProjectCount1: window.ApiData.index.MonitorProjectCount1,
      // MonitorProjectCount2: window.ApiData.index.MonitorProjectCount2,
      // MonitorProjectCount3: window.ApiData.index.MonitorProjectCount3,
      // MonitorProjectCount4: window.ApiData.index.MonitorProjectCount4,
      // MonitorTunnelCount: window.ApiData.index.MonitorTunnelCount,
      // MonitorTunnelCount1: window.ApiData.index.MonitorTunnelCount1,
      // MonitorTunnelCount2: window.ApiData.index.MonitorTunnelCount2,
      // MonitorTunnelCount3: window.ApiData.index.MonitorTunnelCount3,
      // MonitorTunnelCount4: window.ApiData.index.MonitorTunnelCount4,
      // ProjectCompareFault: window.ApiData.index.ProjectCompareFault,
      // ProjectCompareFix: window.ApiData.index.ProjectCompareFix,
      // ProjectList: window.ApiData.index.ProjectList,
      // SignProjectList: window.ApiData.index.SignProjectList,
      // TestLength: window.ApiData.index.TestLength,
      // TestRate: window.ApiData.index.TestRate,
      // TotalFaultBadCount: window.ApiData.index.TotalFaultBadCount,
      // TotalFaultBadRate: window.ApiData.index.TotalFaultBadRate,
      // TotalFaultCount: window.ApiData.index.TotalFaultCount,
      // TotalFaultRate: window.ApiData.index.TotalFaultRate,
      // TotalFixBadCount: window.ApiData.index.TotalFixBadCount,
      // TotalFixBadRate: window.ApiData.index.TotalFixBadRate,
      // TotalFixCount: window.ApiData.index.TotalFixCount,
      // TotalFixRate: window.ApiData.index.TotalFixRate,

      currentTime: dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH(),

      currentPageLevel: dataV.Page.CurrentLevel,		// 大屏当前页面层级，1-全国、2-项目部
      refreshBars: false,
      currentArea: dataV.Config.initPage.area,
      currentProgram: dataV.Config.initPage.program,
      currentTunnel: dataV.Config.initPage.tunnel,
      currentTunnelID: dataV.Config.initPage.tunnelId,
      currentDefect: dataV.Config.initPage.defect,

      chart03index: 1,
      pagesize: 5,				// 项目、隧道、缺陷列表显示记录数
      currentPage: 1,				// 项目、隧道、缺陷列表当前页码
      currentPageTimer: null,
      contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
      pageToggleKey: true,		// 是否允许列表页自动翻页
      maxPage: dataV.Config.initPage.maxPage,

      needCompareIds: [],

      BidSectionCompareFault: [],
      BidSectionCompareFix: [],
      BidSectionList: [],
      CrackLength: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      HonorBookList: [],
      MonitorNotProjectCount: 0,
      MonitorNotTunnelCount: 0,
      MonitorProjectCount: 0,
      MonitorTotalProjectCount: 0,
      MonitorTotalTunnelCount: 0,
      MonitorTunnelCount: 0,
      SignBidSectionList: [],
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultRate: 0,
      TotalFixBadCount: 0,
      TotalFixBadRate: 0,
      TotalFixCount: 0,
      TotalFixRate: 0,
      VideoList:[],

      chart04: null,
      nowTime:''
    }
  },
  props: [],
  methods: {

    sync() {
      this.testingOrgList = [
        { Id: '', Name: '全部检测机构' }
      ]
      // window.ApiData.userRoot.TestingOrgList.forEach(testingOrg => {
      //   this.testingOrgList.push(testingOrg)
      // })
      // this.selectedTestingOrg = dataV.Page.testingOrgId
      // console.log('testingOrgId'+dataV.Page.testingOrgId)
    },
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =new Date(timeStamp).getMonth() + 1 < 10? "0" + (new Date(timeStamp).getMonth() + 1): new Date(timeStamp).getMonth() + 1;
      let date =new Date(timeStamp).getDate() < 10? "0" + new Date(timeStamp).getDate(): new Date(timeStamp).getDate();
      let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
      let mm =new Date(timeStamp).getMinutes() < 10? "0" + new Date(timeStamp).getMinutes(): new Date(timeStamp).getMinutes();
      let ss =new Date(timeStamp).getSeconds() < 10? "0" + new Date(timeStamp).getSeconds(): new Date(timeStamp).getSeconds();
      this.nowTime = year + "年" + month + "月" + date +"日"+" "+hh+":"+mm+':'+ss ;
    },
    nowTimes(){
      this.timeFormate(new Date());
      setInterval(this.nowTimes,1000);
      this.clear()
    },
    clear(){
      clearInterval(this.nowTimes)
      this.nowTimes = null;
    },
    ShowClock(){
      var that = this;
      setInterval(function(){
        const CurrentDateTime = dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH();
        that.currentTime = CurrentDateTime
        if(window.RightProgramComponent) window.RightProgramComponent.currentTime = CurrentDateTime
        if(window.RightTunnelComponent) window.RightTunnelComponent.currentTime = CurrentDateTime
        if(window.CenterMapComponent) window.CenterMapComponent.currentTime = CurrentDateTime
      }, 500)
    },

    p2Radar(){

      var counts = [
        // window.ApiData.index.FaultCount1, 
        // window.ApiData.index.FaultCount2, 
        // window.ApiData.index.FaultCount3, 
        // window.ApiData.index.FaultCount4, 
        this.FaultCount1,this.FaultCount2,this.FaultCount3,this.FaultCount4,
      ];
      var max = counts.slice().sort((a,b)=>{ return b-a })[0];

      console.log('counts:', counts)
      
      if (!this.chart04) this.chart04 = echarts.init(document.getElementById('chart04'));
      var option = {
        tooltip: {},
        radar: {
          shape: 'circle',
          axisName: {
            textStyle: {
              padding: [50]	// 设置一个较大值，将各项的名称隐藏在图表之外
            }
          },
          splitArea: {
            areaStyle: {
              color: ['rgba(0,51,89, 0.8)', 'rgba(1,71,105, 0.8)', 'rgba(0,51,89, 0.8)', 'rgba(1,71,105, 0.8)', 'rgba(0,51,89, 0.8)', 'rgba(1,71,105, 0.8)', 'rgba(0,51,89, 0.8)'],
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.5)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.5)'
            }
          },
          indicator: [
            { name: '厚度不足', max: max},
            { name: '钢筋缺失', max: max},
            { name: '防裂钢筋网', max: max},
            { name: '空洞、不密实', max: max}
          ]
        },
        series: [{
          type: 'radar',
          areaStyle: {
            opacity: 0.5,
            color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
              {
                color: '#aaffff',
                offset: 0
              },
              {
                color: '#0085b9',
                offset: 0.5
              }
            ])
          },
          symbol: 'none',
          lineStyle: {
            color: '#0085b9',
          },
          data: [
            {
              value: counts,
              name: '缺陷数量'
            }
          ]
        }]
      };
      this.chart04.setOption(option);
    },

    toggleContrastBtn(){
        this.contrastIndex = $(event.target).data('index');
    },

  },
  computed: {
    defects(){
      return this.tunnelInfoPatch.defects.filter((item)=>{ return item.type == this.currentDefect })
    },

    getProgs(){		// 返回p2的监控项目和隧道数量
      var returnValue = {};
      if (this.currentArea == '全国区域') {	
        returnValue = this.prog;
      } else if (this.currentArea == '西北区域' || this.currentArea == '西南区域' || this.currentArea == '东北区域' || this.currentArea == '检测基地') {
        var railways_progra_count  = this.prog.railways.areas [this.currentArea]  ? Object.keys(this.prog.railways.areas [this.currentArea].programs).length : 0;
        var highways_progra_count  = this.prog.highways.areas [this.currentArea]  ? Object.keys(this.prog.highways.areas [this.currentArea].programs).length : 0;
        var subways_progra_count   = this.prog.subways.areas [this.currentArea]   ? Object.keys(this.prog.subways.areas  [this.currentArea].programs).length : 0;
        var waterways_progra_count = this.prog.waterways.areas [this.currentArea] ? Object.keys(this.prog.waterways.areas[this.currentArea].programs).length : 0;
        
        var railways_tunnel_count  = this.prog.railways.areas [this.currentArea] ? this.prog.railways.areas [this.currentArea].tunnelCount : 0;
        var highways_tunnel_count  = this.prog.highways.areas [this.currentArea] ? this.prog.highways.areas [this.currentArea].tunnelCount : 0;
        var subways_tunnel_count   = this.prog.subways.areas  [this.currentArea] ? this.prog.subways.areas  [this.currentArea].tunnelCount : 0;
        var waterways_tunnel_count = this.prog.waterways.areas[this.currentArea] ? this.prog.waterways.areas[this.currentArea].tunnelCount : 0;
        
        returnValue = {
          programCount: railways_progra_count + highways_progra_count + subways_progra_count + waterways_progra_count,
          tunnelCount: railways_tunnel_count + highways_tunnel_count + subways_tunnel_count + waterways_tunnel_count,
          railways: {
            programCount: railways_progra_count,
            tunnelCount: railways_tunnel_count,
          },
          highways: {
            programCount: highways_progra_count,
            tunnelCount: highways_tunnel_count,
          },
          subways: {
            programCount: subways_progra_count,
            tunnelCount: subways_tunnel_count,
          },
          waterways: {
            programCount: waterways_progra_count,
            tunnelCount: waterways_tunnel_count,
          },
        }
      }

      return returnValue
    },

    getContrast () {  // 项目的对比列表
      if(this.contrastIndex == 1){  // 返回 缺陷率对比列表
        return this.ProjectCompareFault
      }else{        // 返回 整改合格率对比列表
        return this.ProjectCompareFix
      }
    }

  },
  watch: {
    selectedTestingOrg() {
      dataV.Page.testingOrgId = this.selectedTestingOrg
      dataV.Page.Toggle(this, 1,dataV.Page.CurrentSectionId)

      const that = this
      this.refreshBars = false
      if (this.currentPageLevel === 1) {
        setTimeout(function() {
          that.$nextTick(()=>{
            that.refreshBars = true
            that.p2Radar()
          })
        }, 1000)
      }
      this.p2Radar()
    },

    currentPageLevel() {
      const that = this
      this.refreshBars = false
      if (this.currentPageLevel === 1) {
        setTimeout(function() {
          that.$nextTick(()=>{
            that.refreshBars = true
            that.p2Radar()
          })
        }, 1000)
      }
    },
    
    currentPage(){
      var targetTop = (this.currentPage - 1) * 5 * 30;
      if(dataV.Page.CurrentLevel === 1){
        $('#p2 .text .ProgramList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }else if(dataV.Page.CurrentLevel === 2){
        $('#p3 .tunnelList .ProgramList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }else if(dataV.Page.CurrentLevel === 3){
        targetTop = (this.currentPage - 1) * this.pagesize * 30;
        $('#p4 .defectList .pageList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }
    },

    TunnelCompareFault(){
      this.changePage(this.getContrast.length);
    }

  },
  created() {
			window[this.Id] =  this;
      this.nowTimes()
  },
  mounted() {
    this.ShowClock()
    this.p2Radar()
  },
  components: {
    "dv-img": dvImg,
    'dv-bar-2d': dvBar2d,
  },
};
</script>
