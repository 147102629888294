<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #ffffff;
  font-size: 12px;
}
</style>

<template>
  <div id="app">
      <dv-map  />
      <dv-header />
      <dv-footer />

      <dv-left-screen />
      <dv-right-screen />
      <dv-bottom-screen />
      <!-- <dv-map-index/> -->
      <dv-left-index />
      <dv-left-program />
      <dv-left-tunnel />

      <dv-right-index />
      <dv-right-program />

      <dv-center-list />

      <dv-wins />

      <dv-bottom-index />
      <dv-bottom-program />
      <dv-bottom-tunnel />
  </div>
</template>

<script>
import dataV from "./bin/dataV"

import dvMap from "./components/page/dv-map.vue"
import dvHeader from "./components/page/dv-header.vue"
import dvFooter from "./components/page/dv-footer.vue"

import dvLeftIndex from "./components/page/dv-left-index.vue"
import dvLeftProgram from "./components/page/dv-left-program.vue"
import dvLeftTunnel from "./components/page/dv-left-tunnel.vue"

import dvRightIndex from "./components/page/dv-right-index.vue"
import dvRightProgram from "./components/page/dv-right-program.vue"

import dvWindows from './components/page/dv-wins'

import dvBottomIndexVue from './components/page/dv-bottom-index.vue'
import dvBottomProgramVue from './components/page/dv-bottom-program.vue'
import dvCenterListVue from './components/page/dv-center-list.vue'
import dvBottomTunnelVue from './components/page/dv-bottom-tunnel.vue'
import dvLeftScreenVue from './components/page/dv-left-screen.vue'
import dvRightScreenVue from './components/page/dv-right-screen.vue'
import dvBottomScreenVue from './components/page/dv-bottom-screen.vue'
// import dvMapIndexVue from './components/page/dv-map-index.vue'

export default {
  name: "App",
  components: {
    // "dv-img": dvImg,
    "dv-map": dvMap,
    "dv-header": dvHeader,
    "dv-footer": dvFooter,

    // "dv-left-flow": dvLeftFlow,
    "dv-left-index": dvLeftIndex,
    "dv-left-program": dvLeftProgram,
    "dv-left-tunnel": dvLeftTunnel,

    "dv-right-index": dvRightIndex,
    "dv-right-program": dvRightProgram,

    "dv-center-list": dvCenterListVue,

    'dv-wins': dvWindows,
  
    "dv-bottom-index": dvBottomIndexVue,
    "dv-bottom-program": dvBottomProgramVue,
    "dv-bottom-tunnel": dvBottomTunnelVue,

    "dv-left-screen": dvLeftScreenVue,
    "dv-right-screen": dvRightScreenVue,
    "dv-bottom-screen": dvBottomScreenVue,
    // "dv-map-index" : dvMapIndexVue

},
  data() {
    return {
    }
  },
  created() {
  //  window.ApiData.userRoot.PageRoot = 3
  },
  mounted() {
    // console.log(window.ApiData)
    dataV.Page.Toggle(this, window.ApiData.userRoot.PageRoot - 1, window.ApiData.userRoot.DataId)
    // dataV.Page.Toggle(this, window.ApiData.userRoot.PageRoot - 1, '953dc1a8-e97e-4957-b814-34c482bbd0f3')
    // dataV.Page.Toggle(this, 1, 'c8dab159-0f1d-472c-a3a1-cba722ca3ab9')
    // dataV.Page.Toggle(this, 2, '953dc1a8-e97e-4957-b814-34c482bbd0f3')
    // dataV.Page.Toggle(this, 3, '1469a484-0ce3-4b2f-a303-df6f055cf227')
  }
}
</script>