<style scoped>
#dv-left-index {
  position: absolute;
  left: -502px;
  top: 100px;
  /* left: 8px; */
  width: 400px;
  /* height: 426px; */
  background: url("../../../public/imgs/img/bg/images/右侧浮动边框-26.png") left
    35px top no-repeat;
}
#dv-left-index .title {
  position: absolute;
  left: 35px;
  top: -35px;
  font-size: 18px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding-left: 10px;
}
#dv-left-index .btns {
  position: absolute;
  left: 35px;
  top: 25px;
  width: 300px;
}
#dv-left-index .btns img {
  position: absolute;
  left: 20px;
  top: 0;
  width: 90px;
  height: 28px;
  cursor: pointer;
}
#dv-left-index .btns img:nth-child(2) {
  left: 130px;
}
#dv-left-index .btns img:nth-child(3) {
  left: 240px;
}
#dv-left-index .btns div {
  position: absolute;
  left: 20px;
  top: 0;
  width: 90px;
  color: #87bafd;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  pointer-events: none;
}
#dv-left-index .btns .btn02 {
  left: 130px;
}
#dv-left-index .btns .btn03 {
  left: 240px;
}
#dv-left-index .list {
  position: absolute;
  left: 35px;
  top: 65px;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  width: 160px;
}
#dv-left-index .list-Item {
  margin-bottom: 5px;
  background: url("../../../public/imgs/icon.png") left 4px no-repeat;
  background-size: 13px;
  padding-left: 20px;
}
#dv-left-index i {
  font-style: normal;
  cursor: pointer;
}
#dv-left-index i.selected {
  color: #00fff8;
}
#dv-left-index .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: url("../../../public/imgs/index/右侧浮动边框.png") left bottom
    no-repeat;
}
</style>

<template>
  <div
    :style="'height: ' + (8 * 27 + 105) + 'px; z-index: 5'"
    id="dv-left-index"
  >
    <div class="title">
      <i>{{ AreaName }}</i>
      <span
        v-show="toplist"
        style="
          float: right;
          margin-right: 55px;
          font-size: 12px;
          font-weight: normal;
          margin-top: 5px;
          cursor: pointer;
          color: #37fdfe;
        "
        @click="selected(currentPageLevel - 1, CurrentSectionId)"
        >[ 返回 ]</span
      >
    </div>

    <div class="btns">
      <dv-img
        v-if="userroot < currentPageLevel"
        @click="CompareSections"
        src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"
      ></dv-img>
      <dv-img
        @click="ComparePrograms"
        src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"
      ></dv-img>
      <dv-img
        @click="CompareTunnels"
        src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"
      ></dv-img>

      <div class="btn01">标段对比</div>
      <div class="btn02">分部对比</div>
      <div class="btn03">隧道对比</div>
    </div>
    <div class="list" :style="'height: ' + 8 * 27 + 'px'">
      <vue-scroll style="padding-left: 20px; wdith: 180px">
        <div
          class="list-Item"
          v-for="(item, index) in ProjectList"
          :key="index"
        >
          <i
            @click="selectedBidSection(2, item.Id)"
            :class="currentProgram == item.Id ? 'selected' : ''"
            v-text="item.Name"
          ></i>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import dataV from "../../bin/dataV";
import dvImg from "./dv-img.vue";
import _ from "lodash";
// import dvImgRotator from "./dv-img-rotator.vue";

export default {
  data() {
    return {
      toplist: false,
      Id: "LeftIndexComponent",
      BidSectionList: [],
      ProjectList: [],
      TunnelList: [],
      AreaName: "",
      CurrentSectionId: "",
      currentPageLevel: dataV.Page.CurrentLevel,
      currentArea: dataV.Config.TrainName,

      currentProgram: dataV.Page.CurrentId,
      userroot: window.ApiData.userRoot.PageRoot - 1,
      maxListItems: 8,
    };
  },
  props: ["title"],

  created() {
    window[this.Id] = this;
    console.log(167, this.currentPageLevel);
  },

  methods: {
    CompareSections(all) {
      this.$api
        .request({
          Vue: this,
          apiName: "sectionContrast",
          query: { bidSectionIds: all || "all" },
        })
        .then((response) => {
          if (response.state === "success") {
            const json = JSON.parse(response.data);
            window.CenterWindowsComponent.set("sections", json);
          } else {
            alert(response.message);
          }
        });
    },
    ComparePrograms(all) {
      if (all === "") {
        window.CenterWindowsComponent.set("programs", []);
      } else {
        this.$api
          .request({
            Vue: this,
            apiName: "programContrast",
            query: { projectInfoIds: all || "all" },
          })
          .then((response) => {
            if (response.state === "success") {
              const json = JSON.parse(response.data);
              window.CenterWindowsComponent.set("programs", json);
            } else {
              alert(response.message);
            }
          });
      }
    },
    CompareTunnels(all) {
      if (all === "") {
        window.CenterWindowsComponent.set("tunnels", []);
      } else {
        this.$api
          .request({
            Vue: this,
            apiName: "tunnelContrast",
            query: { tunnelInfoIds: all || "all" },
          })
          .then((response) => {
            if (response.state === "success") {
              const json = JSON.parse(response.data);
              window.CenterWindowsComponent.set("tunnels", json);
            } else {
              alert(response.message);
            }
          });
      }
    },

    sync() {
      this.currentPageLevel = dataV.Page.CurrentLevel;
      this.CurrentSectionId = dataV.Page.CurrentSectionId;
      this.currentProgram = dataV.Page.CurrentId;
    },

    selectedBidSection(level, id) {
      dataV.Page.Toggle(this, level, id);
    },

    selected(level, id) {
      dataV.Page.CurrentId = "";
      dataV.Page.Toggle(this, level, id);
    },
  },
  watch: {
    CurrentSectionId() {
      this.AreaName = _.find(window.ApiData.screen.BidSectionList, {
        Id: this.CurrentSectionId,
      }).Name;
    },
    currentPageLevel() {
      // console.log(275,this.currentPageLevel)
      // console.log('test first')
      if (window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel) {
        this.toplist = true;
      } else {
        this.toplist = false;
      }

      // 设置对比按钮状态
      
        // console.log('test:', window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel)
        // console.log(275,this.currentPageLevel)
        if (window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel) {
          $(document).ready(function(){
          $("#dv-left-index .btns .btn01").show();
          $("#dv-left-index .btns .btn02").css({ left: "130px" });
          $("#dv-left-index .btns .btn03").css({ left: "240px" });
          })
        } else {
          $(document).ready(function(){
          $("#dv-left-index .btns .btn01").hide();
          $("#dv-left-index .btns .btn02").css({ left: "20px" });
          $("#dv-left-index .btns .btn03").css({ left: "130px" });
          })

        }
     
    },
  },
  computed: {
    maxHeight() {
      let rowCount = 0,
        rowIndex = 0;
      try {
        const list = this.ProjectList;
        list.forEach((item) => {
          // item.Name = item.Name + item.Name
          rowCount +=
            item.Name.length > 10 ? (item.Name.length > 20 ? 3 : 2) : 1;
          rowIndex++;
          if (rowIndex >= this.maxListItems) throw new Error("exit forEach");
        });
      } catch (e) {
        e;
      }
      return rowCount > this.maxListItems ? this.maxListItems : rowCount;
    },
  },
  mounted: function () {
    // 加载api数据
        if (window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel) {
          $("#dv-left-index .btns .btn01").show();
          $("#dv-left-index .btns .btn02").css({ left: "130px" });
          $("#dv-left-index .btns .btn03").css({ left: "240px" });
        }
    // window.CenterListComponent = this;
  },
  components: {
    "dv-img": dvImg,
  },
  
};
</script>
